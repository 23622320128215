import { ICartItem, StockStatus } from '@modules/products/types'
import { IShopifyLineItem } from '@lib/shopify/interfaces'

const selectInfoFromCartItems = (
  cartItems: ICartItem[],
  lineItem: IShopifyLineItem
): {
  brand: string
  tags: string[]
  availabilityDate?: string
  deliveryTime?: string
  stockStatus: StockStatus
} => {
  const product = cartItems.find(
    (cartItem) => cartItem.shopifyProductId === lineItem.variant.product.id
  )

  if (!product) {
    return {
      brand: '',
      stockStatus: StockStatus.instock,
      tags: [],
      deliveryTime: '',
      availabilityDate: ''
    }
  }
  const variant = product.variants.find(
    (cartItemVariant) => cartItemVariant.shopifyVariantId === lineItem.variant.id
  )
  return {
    brand: product.brand,
    availabilityDate: variant.availabilityDate,
    tags: product.tags,
    deliveryTime: variant.deliveryTime,
    stockStatus: variant.stockStatus
  }
}

export default selectInfoFromCartItems
