import Image, { StaticImageData } from 'next/image'
import React from 'react'

type TopImageProps = {
  img: StaticImageData
}

const TopImage = ({ img }: TopImageProps) => (
  <div className="relative aspect-w-5 aspect-h-3 overflow-hidden">
    <Image
      src={img}
      alt="Schlafprogramm"
      placeholder="blur"
      fill
      className="object-cover"
      sizes="(min-width: 767px) 896px, 100vw"
    />
  </div>
)

export default TopImage
