import React, { useContext } from 'react'
import debounce from 'lodash.debounce'
import Image from 'next/image'
import { TrashIcon } from '@heroicons/react/24/outline'
import DeliveryTextContent from '@components/DeliveryTextContent'
import { IShopifyLineItem } from '@lib/shopify/interfaces'
import selectInfoFromCartProducts from '@context/selectors'
import ShopContext from '@context/ShopContext'
import SpinnerIcon from '@components/icons/Spinner'
import { hasLineItemDiscount } from '@lib/shopify/utils'
import QuantitySelect from '@components/QuantitySelect'
import LineItemDiscount from '@components/LineItemDiscount'
import classnames from 'classnames'
import CartPrice from '@components/Price/CartPrice'
import Link from 'next/link'

interface ShoppingCartItemProps {
  lineItem: IShopifyLineItem
  interactive: boolean
}

const MAX_QUANTITY = 50

const Item = ({ lineItem, interactive }: ShoppingCartItemProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const { updateLineItem, removeLineItem, checkout, cartItems } = useContext(ShopContext)
  const updateItem = debounce(async (value) => {
    setIsLoading(true)
    await updateLineItem(checkout.id, lineItem, value)
    setIsLoading(false)
  }, 300)
  const debouncedLineItemUpdate = React.useCallback(
    (value) => updateItem(value),
    [checkout.lineItems.length]
  )

  const hasDiscount = hasLineItemDiscount(lineItem)

  const handleQuantityChange = (value) => {
    if (value !== '' && Number(value) < 0) {
      return
    }

    if (Number(value) >= 0) {
      debouncedLineItemUpdate(value)
    }
  }

  const handleRemove = async () => {
    setIsLoading(true)
    await removeLineItem(checkout.id, lineItem)
    setIsLoading(false)
  }

  const { brand, deliveryTime, availabilityDate, stockStatus, tags } = selectInfoFromCartProducts(
    cartItems,
    lineItem
  )

  const variant = lineItem.variant.selectedOptions[0].value !== 'Default Title'

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute inset-1/2">
          <SpinnerIcon />
        </div>
      )}
      <div
        className={classnames(
          'flex relative text-black py-4 transition duration-300 transition-opacity',
          { 'opacity-30': isLoading }
        )}>
        <Link
          href={`/products/${lineItem.variant.product.handle}`}
          key={`/products/${lineItem.variant.product.handle}`}
          className=" pt-1.5 w-1/5">
          {lineItem.variant.image && (
            <Image
              alt={lineItem.variant.image.altText}
              src={{ ...lineItem.variant.image }}
              height={lineItem.variant.image.height}
              width={lineItem.variant.image.width}
              priority
              sizes="100px"
            />
          )}
        </Link>

        <div className="w-4/5 text-base md:text-base text-left pl-4">
          <Link
            href={`/products/${lineItem.variant.product.handle}`}
            key={`/products/${lineItem.variant.product.handle}`}>
            <span className="uppercase text-xs md:text-sm tracking-wide">{brand}</span>
            <div className="font-bold text-sm md:text-base">{lineItem.title}</div>
          </Link>

          {variant && <span className="text-sm md:text-base">{lineItem.variant.title}</span>}

          {hasDiscount && <LineItemDiscount lineItem={lineItem} />}

          <div className="block text-base text-black mt-2">
            {interactive ? (
              <div className="flex items-center">
                <QuantitySelect
                  handleChange={handleQuantityChange}
                  value={lineItem.quantity}
                  min={1}
                  max={MAX_QUANTITY}
                />

                <button
                  className="bg-white focus:outline-none text-black p-2 hover:bg-gray-900 rounded-full hover:text-white md:mt-1 ml-1 md:ml-4"
                  onClick={handleRemove}
                  type="button">
                  <span className="sr-only">Artikel entfernen</span>

                  <TrashIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="text-sm text-right md:text-base ml-auto">
                  <CartPrice lineItem={lineItem} />
                </div>
              </div>
            ) : (
              <div className="text-sm font-medium">Menge: {lineItem.quantity}</div>
            )}
          </div>

          <div className="text-green-600 text-xs md:text-sm mt-3">
            <DeliveryTextContent
              availabilityDate={availabilityDate}
              deliveryTime={deliveryTime}
              stockStatus={stockStatus}
              tags={tags}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Item
