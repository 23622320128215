import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { NextRouter, useRouter } from 'next/router'
import debounce from 'lodash.debounce'

import SearchModal from '@components/search/SearchModal'
import SearchContext from '@context/SearchContext'
import SearchClient from './SearchClient'

const searchClient = new SearchClient()

const getSearchQuery = (router: NextRouter) => {
  const hash = router.asPath.split('#')[1]

  if (!hash) {
    return null
  }
  return hash.split('search=')[1]
}

interface ISearchContainer {
  isSearchOpen: boolean
  setSearchVisibility: (boolean) => void
  iconReference: React.RefObject<HTMLElement>
}

const SearchContainer: React.FC<ISearchContainer> = ({
  isSearchOpen,
  setSearchVisibility,
  iconReference
}) => {
  const {
    query: searchQuery,
    setQuery,
    result,
    setResult,
    pagination,
    setPage,
    setIsOpen
  } = React.useContext(SearchContext)

  const router = useRouter()

  const fetchSearch = debounce(async (value) => {
    if (!value || value.length < 3) {
      return
    }

    if (value === searchQuery) {
      return
    }

    router.push(`#search=${encodeURI(value)}`)
    const responseData = await searchClient.search(value)
    setResult(responseData)
  }, 400)

  const debouncedSearch = React.useCallback((value) => fetchSearch(value), [])

  const handleInputChange = (event) => {
    const { value } = event.target
    setQuery(value)
    debouncedSearch(value)
  }

  const q = getSearchQuery(router)

  useEffect(() => {
    if (q) {
      setIsOpen(true)
      setQuery(decodeURI(q as string))
      debouncedSearch(decodeURI(q as string))
    }
  }, [q])

  const handleClear = () => {
    setQuery('')
  }

  return (
    <AnimatePresence>
      {isSearchOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <SearchModal
            result={result}
            searchQuery={searchQuery}
            handleInputChange={handleInputChange}
            handleClear={handleClear}
            setSearchVisibility={setSearchVisibility}
            pagination={pagination}
            setPage={setPage}
            iconReference={iconReference}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
export default SearchContainer
