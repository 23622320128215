import { IProductSnippet, IProductSnippetsBySlug } from '@modules/products/types'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'

type IProductState = {
  [sku: string]: IProductSnippet
}

type IProductAction = {
  type: string
  payload: {
    productsBySlug: IProductSnippetsBySlug
  }
}
const initialState: IProductState = {}
const productReducer = (
  // eslint-disable-next-line default-param-last
  state: IProductState = initialState,
  action: IProductAction
): IProductState => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        ...action.payload.productsBySlug
      }
    default: {
      return state
    }
  }
}

export default productReducer
