import {
  IDatoCMSAdditionalInformation,
  IDatoCMSBenefit,
  IDatoCMSBrand,
  IDatoCMSImageOrVideo,
  IDatoCMSLongDescription,
  IDatoCMSSeoTags,
  IDatoCMSVideo
} from '@lib/datocms/types/misc'
import { IDatoCMSImage, IDatoCMSProduct } from '@lib/datocms/types'
import { IDatoCMSContentSection } from '@lib/datocms/types/modelApiKeysEnum'
import { IDatoCMSAlternativeHeroProduct } from '@lib/datocms/types/personalizedContent'

export interface IDatoCMSHeroProduct extends IDatoCMSContentSection {
  id: string
  headline: string
  overline: string
  text: string
  heroImage: IDatoCMSImageOrVideo
  product: IDatoCMSProduct
  personalizable: boolean
  alternativeContent: IDatoCMSAlternativeHeroProduct[]
}

export interface IProduct {
  additionalInformation: Array<IDatoCMSAdditionalInformation>
  availabilityDate: string
  isDigital: boolean
  tags: string[]
  benefits: Array<IDatoCMSBenefit>
  brand: IDatoCMSBrand
  deliveryTime: string
  firstImage: IDatoCMSImage
  croppedImage: IDatoCMSImage
  images: Array<IDatoCMSImageOrVideo>
  croppedImagePosition: number
  bundleComponentsSlugs: Array<string>
  isBundle: boolean
  longDescription: IDatoCMSLongDescription
  name: string
  price: string
  priceRange: IPriceRange
  compareAtPriceRange: IPriceRange
  regularPrice: string
  relatedProductSlugs: Array<string>
  seoTags: IDatoCMSSeoTags
  shortDescription: string
  slug: string
  sku: string
  stockStatus: StockStatus
  variantsBySKU: IProductVariantsBySKU
  video: IDatoCMSVideo
  variants: IProductVariant[]
  shopifyProductId: string // needed to retrieve items for cart
  shopifyProductHandle: string
  indexable: boolean
  productType: string
  contentSections: IDatoCMSContentSection[]
  reviews: {
    average: number
    count: number
  }
  dateFirstPublished: string
}

export interface IProductVariant {
  availabilityDate: string
  deliveryTime: string
  name: string
  tags: string[]
  price: string
  regularPrice: string
  stockStatus: StockStatus
  sku: string
  shopifyVariantId: string
  images: Array<IDatoCMSImageOrVideo>
}

export interface IProductSnippet {
  brand: IDatoCMSBrand
  croppedImagePosition: number
  firstImage: IDatoCMSImage
  croppedImage: IDatoCMSImage
  isBundle: boolean
  name: string
  priceRange: IPriceRange
  compareAtPriceRange: IPriceRange
  shopifyProductId: string // needed to retrieve items for cart
  shopifyProductHandle: string
  shortDescription: string
  sku: string
  productType: string
  reviews: {
    average: number
    count: number
  }
}

export interface IProductVariantsBySKU {
  [sku: string]: IProductVariant
}

export interface IProductsBySlug {
  [slug: string]: IProduct
}

export interface IProductSnippetsBySlug {
  [slug: string]: IProductSnippet
}

export interface ICartItem {
  shopifyProductId: string
  brand: string
  tags: string[]
  variants: {
    shopifyVariantId: string
    stockStatus: StockStatus
    availabilityDate?: string
    deliveryTime?: string
  }[]
}

export type IPriceRange = {
  minVariantPrice: {
    amount: string
  }
  maxVariantPrice: {
    amount: string
  }
}

export enum StockStatus {
  onbackorder = 'onbackorder',
  outofstock = 'outofstock',
  instock = 'instock'
}
