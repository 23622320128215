import React from 'react'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import {
  IBrandSearchItem,
  ICategorySearchItem,
  IProductSearchItem,
  IPostSearchItem
} from '@lib/SearchIndexService/types'
import NoResultsPlaceholder from './NoResultsPlaceholder'

type SearchResultsLayoutProps = {
  resultType: string
  results: IProductSearchItem[] | IPostSearchItem[] | IBrandSearchItem[] | ICategorySearchItem[]
  setPage: (page: number) => void
  currentPage: number
  children: React.ReactNode
}

const SearchResultsLayout = ({
  resultType,
  results,
  currentPage,
  setPage,
  children
}: SearchResultsLayoutProps) => {
  const handleShowMoreresults = () => {
    setPage(currentPage + 6)
  }

  return (
    <>
      <h3 className="uppercase font-semibold text-base md:text-lg tracking-wide mb-6">
        {resultType} <span>({results.length})</span>
      </h3>
      {results.length === 0 && <NoResultsPlaceholder resultType={resultType} />}

      {children}

      {results.length > currentPage && (
        <button
          type="button"
          className="btn btn--black mb-9 h-9 text-xs"
          onClick={handleShowMoreresults}>
          <ArrowToRightIcon />
          <span>Weitere Ergebnisse</span>
        </button>
      )}
    </>
  )
}

export default SearchResultsLayout
