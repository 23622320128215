import { IBrowserStorage } from '@lib/browserStorage'
import { ShopifyBrowserClient } from '@lib/shopify/api/checkout/CartAPI'
import { LOCAL_STORAGE_KEY } from '@utils/constants'
import { createToast } from '@components/Toast/Toast'
import { IShopifyCheckout } from './interfaces'
import { parseDiscountCode } from './utils'

export default class CheckoutSingleton {
  private getCheckoutPromise: Promise<IShopifyCheckout> | null

  private checkout: IShopifyCheckout | null

  private shopifyClient: ShopifyBrowserClient = null

  private browserStorage: IBrowserStorage

  constructor(client: ShopifyBrowserClient, storage: IBrowserStorage) {
    this.shopifyClient = client
    this.browserStorage = storage
    this.getCheckoutPromise = null
    this.checkout = null
  }

  private async init() {
    if (this.getCheckoutPromise) {
      return this.getCheckoutPromise
    }
    const existingCheckoutID = this.browserStorage.getItem(LOCAL_STORAGE_KEY) || null

    if (existingCheckoutID && existingCheckoutID !== 'null') {
      this.getCheckoutPromise = this.shopifyClient.checkout.fetch(existingCheckoutID)
    } else {
      this.getCheckoutPromise = this.shopifyClient.checkout.create()
    }

    return this.getCheckoutPromise
  }

  public reset(): void {
    this.checkout = null
    this.getCheckoutPromise = null
  }

  public async getCheckout(): Promise<IShopifyCheckout> {
    if (this.checkout) {
      return this.checkout
    }
    const checkout = await this.init()
    this.checkout = checkout
    return checkout
  }

  public async applyDiscount(code: string | string[]): Promise<IShopifyCheckout> {
    const checkout = await this.init()
    const updatedCheckout = await this.shopifyClient.checkout.addDiscount(
      checkout.id,
      code as string
    )

    const isCodeApplied = updatedCheckout.discountApplications.some(
      (discount) => parseDiscountCode(discount.code) === parseDiscountCode(code)
    )

    if (isCodeApplied) {
      createToast(
        `Dein Gutscheincode ${code.toString().toUpperCase()} wurde erfolgreich aktiviert.`,
        'success'
      )
    }

    this.checkout = updatedCheckout
    return updatedCheckout
  }
}
