import { IShopifyCheckout, IShopifyLineItem, IShopifyProduct } from './interfaces'
import { VALID_DISCOUNT_TARGET_SELECTIONS } from './constants'

export const hasGenericDiscount = (checkout: IShopifyCheckout): boolean =>
  checkout.discountApplications.filter(
    (discountApplication) => discountApplication.targetSelection === 'ALL'
  ).length > 0

export const hasLineItemDiscount = (lineItem: IShopifyLineItem): boolean =>
  lineItem.discountAllocations
    .filter((allocation) => Number(allocation.allocatedAmount.amount) > 0)
    .filter((allocation) =>
      VALID_DISCOUNT_TARGET_SELECTIONS.includes(allocation.discountApplication.targetSelection)
    ).length > 0

export const hasHigherCompareAtPrice = (lineItem: IShopifyLineItem): boolean =>
  !!(
    lineItem.variant.compareAtPrice &&
    parseFloat(lineItem.variant.compareAtPrice.amount) > parseFloat(lineItem.variant.price.amount)
  )

export const getTotalDiscountAmount = (checkout: IShopifyCheckout): number =>
  Number(checkout.lineItemsSubtotalPrice.amount) - Number(checkout.subtotalPrice.amount)

export const getGenericDiscountLabel = (checkout: IShopifyCheckout): string => {
  const applicableDiscount = checkout.discountApplications.find(
    (discount) => discount.targetSelection === 'ALL' && discount.applicable === true
  )

  if (!applicableDiscount) {
    return ''
  }

  return applicableDiscount.code
}

export const getDiscountedItemPrice = (lineItem: IShopifyLineItem): number => {
  const regularPrice = Number(lineItem.variant.price.amount) * lineItem.quantity
  const discountAllocations = lineItem.discountAllocations.filter((allocation) =>
    VALID_DISCOUNT_TARGET_SELECTIONS.includes(allocation.discountApplication.targetSelection)
  )

  const totalDiscount = discountAllocations.reduce(
    (acc, allocation) => acc + Number(allocation.allocatedAmount.amount),
    0
  )

  return regularPrice - totalDiscount
}

export const parseDiscountCode = (code: string | string[]): string => {
  if (typeof code === 'string') {
    return code.toLocaleLowerCase()
  }

  return code[0].toLocaleLowerCase()
}

export const parseGraphProductId = (graphId: string): number =>
  Number(graphId.split('gid://shopify/Product/')[1])

export const isDigitalProduct = (product: IShopifyProduct): boolean =>
  product.tags?.includes('digital_product') ?? false
