import React, { ChangeEvent, useRef } from 'react'
import { AnswerOption, Step } from '@modules/sleepcheck/types'
import classNames from 'classnames'
import { CheckIcon } from '@heroicons/react/20/solid'

type CheckboxProps = {
  option: AnswerOption
  step: Step
  id: string
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  isFirst: boolean
  isLast: boolean
}

const Checkbox = ({ option, step, id, isFirst, isLast, handleChange }: CheckboxProps) => {
  const [isChecked, setIsChecked] = React.useState(false)
  const ref = useRef<HTMLInputElement>(null)
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    handleChange(event)
  }

  return (
    <div className="border-2 -my-[1px] first:rounded-t last:rounded-b">
      <input
        ref={ref}
        type="checkbox"
        id={id}
        name={step.slug}
        value={option.label}
        className="peer appearance-none hidden"
        onChange={handleOnChange}
      />
      <label
        htmlFor={id}
        className={classNames(
          'relative p-2 -m-[2px] flex items-start justify-between border-2 border-gray-300 text-gray-600 cursor-pointer peer-checked:border-black peer-checked:text-black peer-checked:z-1',
          {
            'rounded-t': isFirst,
            'rounded-b': isLast
          }
        )}>
        <div className="text-sm w-full">{option.label}</div>
        <div
          className={classNames('shrink-0 w-5 h-5 ml-1 rounded-full border-2', {
            'border-black text-black': isChecked,
            'border-gray-300 text-white': !isChecked
          })}>
          <CheckIcon
            className={classNames({
              'text-black': isChecked,
              'text-white': !isChecked
            })}
          />
        </div>
      </label>
    </div>
  )
}

export default Checkbox
