import React, { useContext, useState } from 'react'
import Link from 'next/link'

import { motion } from 'framer-motion'
import classNames from 'classnames'
import { IMenuItem } from '@modules/menu/types'
import { getExternalOrInternalLink } from '@modules/common/utils'
import UiContext from '@context/UiContext'
import { useIsMedium } from 'hooks/useMediaQuery'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

type MenuLinkProps = {
  link: IMenuItem['link']
  title: string
  onClick?: () => void
  level: number
}

const MenuLink = ({ link, title, onClick, level }: MenuLinkProps) => {
  const externalOrInternalLink = getExternalOrInternalLink(link, title) || '#'
  return (
    <Link
      href={externalOrInternalLink}
      onClick={onClick}
      className={classNames('px-4 py-2 md:py-0.5', { 'md:normal-case': level === 3 })}>
      {title}
    </Link>
  )
}

interface ICollapsibleMenuItem {
  menuItem: IMenuItem
  open: boolean
  index: number
  setOpenIndex: (index: number) => void
  openIndex: number
  level: number
}

const CollapsibleMenuItem: React.FC<ICollapsibleMenuItem> = ({
  menuItem,
  open,
  index,
  setOpenIndex,
  openIndex,
  level
}) => {
  const isOpen = useIsMedium() ? true : open // open is only used on mobile
  const [openChildIndex, setOpenChildIndex] = useState(-1)
  const { closeMobileMenu } = useContext(UiContext)
  const isFirstLevel = level === 1

  const variants = {
    open: { height: 'auto' },
    collapsed: { height: 0 }
  }

  const handelMenuItemClick = () => {
    if (openIndex === index) {
      setOpenIndex(-1)
    } else {
      setOpenIndex(index)
    }
  }

  const hasChildren = menuItem.children?.length > 0

  return (
    <li
      className={classNames(
        'whitespace-nowrap uppercase flex flex-wrap w-full justify-between items-center border-t border-b md:w-auto md:flex-col md:justify-start md:items-start md:flex-nowrap',
        {
          'border-gray-300 md:border-transparent': isOpen && isFirstLevel,
          'border-transparent': !(isOpen && isFirstLevel),
          'md:px-4 md:pt-1 md:pb-4 lg:px-8': isFirstLevel
        }
      )}
      key={menuItem.id}>
      {hasChildren && (
        <>
          {!menuItem.link && (
            <button
              className="w-full flex justify-between px-4 py-3 md:w-auto"
              type="button"
              onClick={handelMenuItemClick}>
              <span className="uppercase">{menuItem.title}</span>
              <ChevronRightIcon
                className={classNames('w-6 transition-transform md:hidden', {
                  'rotate-90': isOpen
                })}
              />
            </button>
          )}
          {menuItem.link && (
            <>
              <MenuLink onClick={closeMobileMenu} {...menuItem} level={level} />
              <button
                type="button"
                aria-label={isOpen ? 'Close' : 'Open'}
                className="px-4 py-2 grow flex justify-end md:hidden"
                onClick={handelMenuItemClick}>
                <ChevronRightIcon
                  className={classNames('w-6 transition-transform', {
                    'rotate-90': isOpen
                  })}
                />
              </button>
            </>
          )}
        </>
      )}
      {!hasChildren && <MenuLink onClick={closeMobileMenu} {...menuItem} level={level} />}
      {hasChildren && (
        <motion.div
          className="overflow-hidden w-screen font-normal md:w-auto md:overflow-visible"
          variants={variants}
          transition={{ bounce: 0, ease: 'easeInOut' }}
          animate={isOpen ? 'open' : 'collapsed'}>
          {menuItem.children && menuItem.children.length > 0 && (
            <ul className={classNames({ 'ml-2': !isFirstLevel })}>
              {menuItem.children.map((grandChild, childIndex) => (
                <CollapsibleMenuItem
                  menuItem={grandChild}
                  open={childIndex === openChildIndex}
                  index={childIndex}
                  setOpenIndex={setOpenChildIndex}
                  openIndex={openChildIndex}
                  key={grandChild.id}
                  level={level + 1}
                />
              ))}
            </ul>
          )}
        </motion.div>
      )}
    </li>
  )
}

export default CollapsibleMenuItem
