import React, { createContext, SetStateAction, useState } from 'react'

import AuthClient from '@modules/auth/AuthClient'
import { SleepProgramUser } from '@lib/sleepProgram/types'

type SleepProgramContextProps = {
  children?: JSX.Element
}

const initialUser: SleepProgramUser = {
  email: '',
  firstName: '',
  lastName: '',
  isSleepProgramTester: null
}
const authClient = new AuthClient()

/* eslint-disable */
const defaultValues = {
  user: initialUser,
  setUser: (user: SetStateAction<SleepProgramUser>) => {},
  submitUserData: () => {},
  resetSleepProgram: () => {}
}
/* eslint-enable */

const SleepProgramContext = createContext(defaultValues)

export const SleepProgramContextProvider = ({ children }: SleepProgramContextProps) => {
  const [user, setUser] = useState<SleepProgramUser>(initialUser)

  const submitUserData = async () => {
    try {
      await authClient.saveSleepProgramUser({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        isSleepProgramTester: user.isSleepProgramTester
      })
    } catch (err) {
      console.error(err)
    }
  }

  const resetSleepProgram = async () => {
    setUser(initialUser)
  }
  return (
    <SleepProgramContext.Provider
      value={{
        user,
        setUser,
        submitUserData,
        resetSleepProgram
      }}>
      {children}
    </SleepProgramContext.Provider>
  )
}

export default SleepProgramContext
