import { BackspaceIcon } from '@heroicons/react/24/outline'
import React from 'react'

interface ISearchInput {
  searchQuery: string
  handleInputChange: (event) => void
  handleClear: (event) => void
}

const SearchInput: React.FC<ISearchInput> = ({ searchQuery, handleInputChange, handleClear }) => (
  <form className="w-full " onSubmit={(event) => event.preventDefault()}>
    <div className="flex w-full relative ">
      {/* eslint-disable jsx-a11y/no-autofocus */}
      <input
        className={`w-full  text-base font-semibold md:text-lg placeholder:text-black placeholder:font-semibold rounded md:rounded p-2 outline-none ring-2 ${
          searchQuery ? 'ring-gray-300 active:ring-grey' : ' ring-black active:ring-black'
        } `}
        id="search"
        autoFocus
        autoComplete="off"
        placeholder="Was suchst du?"
        value={searchQuery}
        onChange={handleInputChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur()
          }
        }}
      />
      <span className="h-full ">
        <button type="button" onClick={handleClear}>
          <BackspaceIcon
            className={`h-2/3 absolute right-0 top-2 mr-3 ${
              searchQuery ? 'opacity-1' : 'opacity-30'
            } `}
          />
        </button>
      </span>
    </div>
  </form>
)

export default SearchInput
