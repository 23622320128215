import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { IBrandSearchItem } from '@lib/SearchIndexService/types'
import SearchContext from '@context/SearchContext'

interface BrandSearchResultsProps {
  pagination: number
  brands: IBrandSearchItem[]
}

const BrandSearchResults = ({ pagination, brands }: BrandSearchResultsProps) => {
  const search = React.useContext(SearchContext)
  return (
    <div className="-ml-2 flex flex-wrap pb-9">
      {brands.slice(0, pagination).map((brand) => (
        <div className="w-1/3" key={brand.objectID}>
          <div className="bg-gray-300 m-2 px-2">
            <Link legacyBehavior href={brand.link}>
              <a
                className="relative block w-full aspect-w-4 aspect-h-3"
                href={brand.link}
                onClick={() => search.setIsOpen(false)}>
                <Image
                  alt={brand.name}
                  src={brand.image?.responsiveImage?.src || brand.image.url}
                  fill
                  className="mix-blend-multiply object-contain"
                  sizes="(min-width: 767px) 25vw, 50vw"
                />
              </a>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BrandSearchResults
