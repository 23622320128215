import React from 'react'
import { IShopifyCheckout } from '@lib/shopify/interfaces'
import { getGenericDiscountLabel, getTotalDiscountAmount } from '@lib/shopify/utils'
import CouponTag from '@components/icons/CouponTag'
import { shopifyToEuro } from '../../../../utils'

interface IProps {
  checkout: IShopifyCheckout
}

const DiscountSubtotal: React.FC<IProps> = (props: IProps) => {
  const { checkout } = props
  return (
    <>
      <div className="flex flex-wrap font-bold text-sm md:text-base">
        <span>Zwischensumme:</span>
        <span className="ml-auto">
          {shopifyToEuro(Number(checkout.lineItemsSubtotalPrice.amount))}
        </span>
      </div>
      <div className="flex flex-wrap text-sm md:text-base items-center">
        <span className="font-bold mr-3">Rabatt: </span>
        <CouponTag />
        <span className="uppercase self-center text-xs md:text-sm tracking-wide ml-2">
          {getGenericDiscountLabel(checkout)}
        </span>{' '}
        <div className="ml-auto font-bold">
          {'- '}
          {shopifyToEuro(getTotalDiscountAmount(checkout))}
        </div>
      </div>
      <div className="p-3">
        <hr />
      </div>
    </>
  )
}

export default DiscountSubtotal
