import React, { useEffect, useRef, useState } from 'react'
import { Step } from '@modules/sleepcheck/types'

type RangeProps = {
  step: Step
  setCurrentValue: (value: number) => void
}

const Range = ({ step, setCurrentValue }: RangeProps) => {
  const THUMB_SIZE = 34
  const [rangeVal, setRangeVal] = useState(50)
  const [dropElementPositionLeft, setDropElementPositionLeft] = useState('')
  const dropElement = useRef<HTMLDivElement>()
  const inputElement = useRef<HTMLInputElement>()

  const setPosition = (target) => {
    const min = parseInt(target.min.toString(), 10)
    const max = parseInt(target.max.toString(), 10)
    const val = parseInt(target.value.toString(), 10)

    const percent = ((val - min) / (max - min)) * 100
    setDropElementPositionLeft(
      `calc(${percent}% + ${THUMB_SIZE / 2 - percent * 0.01 * THUMB_SIZE}px)`
    )
  }

  const handleRangeChange = () => {
    const target = inputElement.current
    const val = parseInt(target.value, 10)

    setRangeVal(val)
    setCurrentValue(val)
    setPosition(target)
  }

  useEffect(() => {
    setPosition(inputElement.current)
  }, [rangeVal])

  useEffect(() => {
    handleRangeChange()
  }, [])

  return (
    <div className="relative my-6">
      <div
        ref={dropElement}
        className="absolute bg-black w-9 h-9 text-white -translate-x-1/2 -translate-y-1.5 rounded-tl-full rounded-tr-full rounded-bl-full rotate-45 flex justify-center items-center"
        style={{ left: `${dropElementPositionLeft}` }}>
        <span className="block -rotate-45 font-bold text-sm">{rangeVal}</span>
      </div>
      <input
        ref={inputElement}
        className="mt-14 appearance-none w-full h-2 rounded-full bg-gray-200 bg-gradient-to-r from-black to-black bg-no-repeat"
        style={{ backgroundSize: `${rangeVal}% 100%` }}
        type="range"
        min="0"
        max="100"
        value={rangeVal}
        id={step.slug}
        onChange={handleRangeChange}
      />
      <div className="flex justify-between text-sm mt-2">
        <span>{step.answer.options[0].label}</span>
        <span>{step.answer.options[1].label}</span>
      </div>
    </div>
  )
}

export default Range
