import React, { useContext } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import UiContext from '@context/UiContext'

const Logo: React.FC = () => {
  const { closeMobileMenu } = useContext(UiContext)
  return (
    <Link legacyBehavior prefetch={false} href="/">
      <a onClick={closeMobileMenu} className="h-14 md:h-16 w-20 md:w-24 relative">
        <Image fill src="/images/logo/yoself-logo.svg" alt="yoself" priority />
      </a>
    </Link>
  )
}

export default Logo
