import React from 'react'
import daysjs from 'dayjs'
import { StockStatus } from '@modules/products/types'
import { DEFAULT_DELIVERY_TIME } from '@utils/constants'

export interface IDeliveryTextContent {
  stockStatus: StockStatus
  deliveryTime: string
  availabilityDate?: string
  tags: string[]
}

export const formatDeliveryDate = (date: string): string => daysjs(date).format('DD.MM.YYYY')

const DeliveryTextContent: React.FC<IDeliveryTextContent> = ({
  stockStatus,
  availabilityDate,
  deliveryTime,
  tags
}) => {
  let text: string
  let color: string
  const isDigital = tags?.includes('digital_product')
  switch (stockStatus) {
    case 'onbackorder':
      text = availabilityDate
        ? `Ab dem ${formatDeliveryDate(availabilityDate)} lieferbar.<br/>Jetzt vorbestellen.`
        : 'Aktuell ausverkauft.<br/>Jetzt vorbestellen'

      color = 'text-green-600'

      break
    case 'outofstock':
      text = 'ausverkauft'
      color = 'text-red-500'

      break
    default:
      text = `Sofort verfügbar. ${
        isDigital ? `Digitales Produkt` : `Lieferzeit ${deliveryTime || DEFAULT_DELIVERY_TIME}`
      } `
      color = 'text-green-600'
  }

  return <p className={color} dangerouslySetInnerHTML={{ __html: text }} />
}

export default DeliveryTextContent
