import React from 'react'

import { IShopifyLineItem } from '@lib/shopify/interfaces'
import {
  getDiscountedItemPrice,
  hasHigherCompareAtPrice,
  hasLineItemDiscount
} from '@lib/shopify/utils'
import { shopifyToEuro, toEuro } from '../../utils'

interface ICartPrice {
  lineItem: IShopifyLineItem
}

const CartPrice: React.FC<ICartPrice> = ({ lineItem }) => {
  const hasDiscount = hasLineItemDiscount(lineItem)
  const regularPrice = toEuro(parseFloat(lineItem.variant.price.amount) * lineItem.quantity)
  const compareAtPrice = hasHigherCompareAtPrice(lineItem)
    ? toEuro(parseFloat(lineItem.variant.compareAtPrice.amount))
    : null

  const renderDiscountPrice = (): string => {
    const discountPrice = getDiscountedItemPrice(lineItem)

    if (discountPrice === 0) {
      return 'Kostenlos'
    }
    return shopifyToEuro(discountPrice)
  }

  if (compareAtPrice) {
    return (
      <>
        <div className="line-through text-gray-500" data-testid="compare-price">
          {compareAtPrice}
        </div>
        <div className="text-red-700">{regularPrice}</div>
      </>
    )
  }

  if (!hasDiscount) {
    return <div data-testid="regular-price">{regularPrice}</div>
  }

  return (
    <>
      <div className="line-through text-gray-500">{regularPrice}</div>
      <div data-testid="discount-price">{renderDiscountPrice()}</div>
    </>
  )
}

export default CartPrice
