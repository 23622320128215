import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'

import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import { CookieSerializeOptions, serialize } from 'cookie'
import { trackCookieConsentGiven } from '@utils/tracking'

const CookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const bannerRef = useRef<HTMLDivElement>()

  const COOKIE_PREFIX = '_yoself'

  const getCookies = () =>
    document.cookie.split(';').reduce((res, c) => {
      const [key, val] = c.trim().split('=').map(decodeURIComponent)
      try {
        return Object.assign(res, { [key]: JSON.parse(val) })
      } catch (e) {
        return Object.assign(res, { [key]: val })
      }
    }, {})

  const setNoConsentCookie = () => {
    const options: CookieSerializeOptions = {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
      secure: true,
      path: '/'
    }
    document.cookie = serialize(COOKIE_PREFIX, `no-consent`, options)
  }

  const onConfirmClicked = () => {
    trackCookieConsentGiven()

    fetch('/api/cookie', {}).then(() => {
      setShowCookieBanner(false)
    })
  }

  const onDeclinedClick = () => {
    setNoConsentCookie()
    setShowCookieBanner(false)
  }

  useEffect(() => {
    const cookies = getCookies()
    if (!(COOKIE_PREFIX in cookies)) {
      setShowCookieBanner(true)
      setTimeout(() => {
        if (bannerRef.current) bannerRef.current.classList.add('slide-up')
      }, 1000)
    }
  }, [bannerRef?.current])

  return (
    showCookieBanner && (
      <div
        ref={bannerRef}
        className="cookie-banner sticky bottom-0 right-0 ml-auto sm:pb-5 flex justify-end z-50 -mt-96 w-full max-w-2xl">
        <div className="w-full px-0 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-white shadow-2xl sm:p-3">
            <div className="flex items-center justify-between flex-wrap relative">
              <div className="w-full absolute top-0 -mt-6 md:-mt-12">
                <div className="rounded-full bg-white w-12 h-12 md:w-20 md:h-20 mx-auto top-0 p-2 md:p-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 59">
                    <g id="Ebene_2" data-name="Ebene 2">
                      <g id="Ebene_1-2" data-name="Ebene 1">
                        <path d="M29,2a13.2768,13.2768,0,0,1,7.942,2.0544c-.03.076-.0589.1505-.0861.22C36.0243,6.39,34.3148,10.74,38.4465,14.309c.0065.2544-.0161.6491-.0338.9583-.1009,1.7594-.27,4.7042,2.1731,7.1469A7.4334,7.4334,0,0,0,46.09,24.6877c.5073,0,.9674-.0391,1.3487-.0746,1.6493,4.1205,5.0419,4.6142,7.1314,4.9182a8.5557,8.5557,0,0,1,1.42.28A1.5318,1.5318,0,0,1,56,30,27,27,0,0,1,2,30C2,14.8224,14.3645,2,29,2m0-2C12.9837,0,0,13.9837,0,30a29,29,0,0,0,58,0c0-4.4381-7,0-9-7-.0994-.3478-.3988-.4416-.8426-.4416-.5207,0-1.24.1293-2.0673.1293A5.47,5.47,0,0,1,42,21c-3-3-.6852-6.9482-2-8-5-4,.2264-9.1824-1-10A15.4815,15.4815,0,0,0,29,0Z" />
                        <circle cx="15" cy="27" r="1" fill="#fff" />
                        <path d="M15,25a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
                        <circle cx="35" cy="45" r="1" fill="#fff" />
                        <path d="M35,43a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
                        <circle cx="32" cy="30" r="1" fill="#fff" />
                        <path d="M32,28a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
                        <circle cx="18" cy="43" r="1" fill="#fff" />
                        <path d="M18,41a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
                        <circle cx="48" cy="37" r="1" fill="#fff" />
                        <path d="M48,35a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
                        <circle cx="22" cy="14" r="1" fill="#fff" />
                        <path d="M22,12a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <span className="w-full text-base md:text-xl font-medium text-black text-center mt-6 mb-0 md:mb-0">
                Cookies erlauben?
              </span>
              <div className="relative p-2 md:p-4">
                <p className="block text-xs md:text-sm overflow-scroll h-56 md:h-56 pb-8">
                  <a href="#datennutzung" className="underline" title="Infos zum Tracking">
                    Wir von yoself.de und unsere Partner
                  </a>
                  &nbsp;brauchen deine Zustimmung (Klick auf „OK”) bei vereinzelten Datennutzungen,
                  um Informationen auf einem Gerät zu speichern und/oder abzurufen (IP-Adresse,
                  Nutzer-ID, Browser-Informationen). Die Datennutzung erfolgt für personalisierte
                  Anzeigen und Inhalte, Anzeigen- und Inhaltsmessungen sowie um Erkenntnisse über
                  Zielgruppen und Produktentwicklungen zu gewinnen. Mehr Infos zur Einwilligung und
                  zu Einstellungsmöglichkeiten gibt’s jederzeit&nbsp;
                  <Link
                    href="/datenschutz#tracking"
                    className="underline"
                    title="Infos zum Tracking">
                    hier
                  </Link>
                  . Mit Klick auf den Link &quot;Cookies ablehnen&quot; kannst du deine Einwilligung
                  jederzeit ablehnen.
                  <br />
                  <br />
                  <strong id="datennutzung">Datennutzungen</strong>
                  <br />
                  Wir arbeiten mit Partnern zusammen, die von Ihrem Endgerät abgerufene Daten
                  (Trackingdaten) auch zu eigenen Zwecken (z.B. Profilbildungen) / zu Zwecken
                  Dritter verarbeiten. Vor diesem Hintergrund erfordert nicht nur die Erhebung der
                  Trackingdaten, sondern auch deren Weiterverarbeitung durch diese Anbieter einer
                  Einwilligung. Die Trackingdaten werden erst dann erhoben, wenn Sie auf den in dem
                  Banner auf yoself.de wiedergebenden Button „OK” anklicken.
                  <br />
                  <br />
                  <strong>Bei den Partnern handelt es sich um die folgenden Unternehmen:</strong>
                  Facebook Ireland Limited, Google Inc. Microsoft Ireland Operations Limited Weitere
                  Informationen zu den Datenverarbeitungen durch diese Partner findest Du in der
                  Datenschutzerklärung auf yoself.de
                </p>
                <div className="-ml-6 bg-gradient-to-t from-white h-12 absolute w-full bottom-0" />
              </div>

              <div className="w-full max-w-xs flex flex-wrap justify-between mx-auto">
                <button
                  className="btn btn--black w-full justify-center"
                  onClick={onConfirmClicked}
                  type="button">
                  <ArrowToRightIcon />
                  <span>Ok</span>
                </button>

                <div className="flex w-full text-center justify-between py-4 text-sm">
                  <button
                    type="button"
                    onClick={onDeclinedClick}
                    className="text-gray-500 underline hover:text-black px-2">
                    Cookies ablehnen
                  </button>
                  <Link
                    className="text-gray-500 underline hover:text-black px-2"
                    href="/datenschutz">
                    Mehr Informationen
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CookieBanner
