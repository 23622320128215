import img_10 from '@assets/images/sleepcheck/10.jpg'
import img_11 from '@assets/images/sleepcheck/11.jpg'
import img_12 from '@assets/images/sleepcheck/12.jpg'
import img_13 from '@assets/images/sleepcheck/13.jpg'
import img_20 from '@assets/images/sleepcheck/20.jpg'
import img_21 from '@assets/images/sleepcheck/21.jpg'
import img_22 from '@assets/images/sleepcheck/22.jpg'
import img_23 from '@assets/images/sleepcheck/23.jpg'
import img_30 from '@assets/images/sleepcheck/30.jpg'
import img_31 from '@assets/images/sleepcheck/31.jpg'
import img_32 from '@assets/images/sleepcheck/32.jpg'
import img_33 from '@assets/images/sleepcheck/33.jpg'
import img_40 from '@assets/images/sleepcheck/40.jpg'
import img_41 from '@assets/images/sleepcheck/41.jpg'
import img_42 from '@assets/images/sleepcheck/42.jpg'
import img_43 from '@assets/images/sleepcheck/43.jpg'
import img_44 from '@assets/images/sleepcheck/44.jpg'
import img_50 from '@assets/images/sleepcheck/50.jpg'
import img_60 from '@assets/images/sleepcheck/60.jpg'
import img_70 from '@assets/images/sleepcheck/70.jpg'
import img_71 from '@assets/images/sleepcheck/71.jpg'
import img_72 from '@assets/images/sleepcheck/72.jpg'
import img_73 from '@assets/images/sleepcheck/73.jpg'
import img_74 from '@assets/images/sleepcheck/74.jpg'
import img_76 from '@assets/images/sleepcheck/76.jpg'
import img_77 from '@assets/images/sleepcheck/77.jpg'
import img_78 from '@assets/images/sleepcheck/78.jpg'
import img_79 from '@assets/images/sleepcheck/79.jpg'
import img_710 from '@assets/images/sleepcheck/710.jpg'
import img_712 from '@assets/images/sleepcheck/712.jpg'
import img_713 from '@assets/images/sleepcheck/713.jpg'
import img_714 from '@assets/images/sleepcheck/714.jpg'
import img_715 from '@assets/images/sleepcheck/715.jpg'
import img_80 from '@assets/images/sleepcheck/80.jpg'
import img_81 from '@assets/images/sleepcheck/81.jpg'
import img_82 from '@assets/images/sleepcheck/82.jpg'
import img_83 from '@assets/images/sleepcheck/83.png'
import img_84 from '@assets/images/sleepcheck/84.jpg'
import img_85 from '@assets/images/sleepcheck/85.jpg'
import img_90 from '@assets/images/sleepcheck/90.jpg'
import img_registration from '@assets/images/sleepcheck/improveSleep.jpg'
import { answerType, Step } from '@modules/sleepcheck/types'

enum category {
  SCHLAFQUALITAET = 'Schlafqualität',
  SCHLAFDAUER = 'Schlafdauer',
  SCHLAFKONSTITUTION = 'Schlafkonstitution',
  SCHLAFSTOERUNGEN = 'Schlafstörungen',
  SCHLAFHYGIENE = 'Schlafhygiene',
  MASSNAHMEN = 'Maßnahmen',
  ALLGEMEIN = 'Allgemein'
}

export const steps: { [key: string]: Step } = {
  quality: {
    slug: 'quality',
    image: img_10,
    question: 'Wie würdest du deine derzeitige Schlafqualität beurteilen?',
    category: category.SCHLAFQUALITAET,
    answer: {
      type: answerType.RANGE,
      getNextStepSlug: () => 'wellRested',
      options: [{ label: 'Sehr schlecht' }, { label: 'Sehr gut' }]
    }
  },
  wellRested: {
    slug: 'wellRested',
    image: img_12,
    question: 'Fühlst du dich tagsüber oft fit und ausgeruht?',
    category: category.SCHLAFQUALITAET,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'easyStart',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  easyStart: {
    slug: 'easyStart',
    image: img_13,
    question: 'Fällt es dir in der Regel leicht, morgens aus dem Bett zu kommen?',
    category: category.SCHLAFQUALITAET,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'hours',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  hours: {
    slug: 'hours',
    image: img_11,
    question: 'Wieviele Stunden pro Nacht schläfst du in etwa durchschnittlich?',
    category: category.SCHLAFDAUER,
    answer: {
      type: answerType.RADIO,
      getNextStepSlug: (answerMap: Map<string, boolean | number | string>) => {
        if (
          ((answerMap.get('quality') as number) > 70 && answerMap.get('hours') === '5-7') ||
          ((answerMap.get('quality') as number) > 70 && answerMap.get('hours') === '7-8') ||
          ((answerMap.get('quality') as number) > 70 && answerMap.get('hours') === 'mehr als 8')
        ) {
          return 'devices'
        }
        return 'stressed'
      },
      options: [
        {
          label: 'mehr als 8'
        },
        {
          label: '7-8'
        },
        {
          label: '5-7'
        },
        {
          label: '3-5'
        },
        {
          label: '0-3'
        }
      ]
    }
  },
  stressed: {
    slug: 'stressed',
    image: img_20,
    question: 'Fühlst du dich im Alltag oft gestresst?',
    category: category.SCHLAFKONSTITUTION,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'windDown',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  windDown: {
    slug: 'windDown',
    image: img_21,
    question: 'Fällt es dir häufig schwer, abends abzuschalten?',
    category: category.SCHLAFKONSTITUTION,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'restless',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  restless: {
    slug: 'restless',
    image: img_22,
    question: 'Leidest du unter innerer Unruhe?',
    category: category.SCHLAFKONSTITUTION,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'worries',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  worries: {
    slug: 'worries',
    image: img_23,
    question: 'Kreisen deine Gedanken oft um Sorgen und/oder Ängste?',
    category: category.SCHLAFKONSTITUTION,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'comeToRest',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  comeToRest: {
    slug: 'comeToRest',
    image: img_30,
    question: 'Fällt es dir oft schwer, einzuschlafen?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: (answerMap: Map<string, boolean | number | string>) =>
        answerMap.get('comeToRest') === true ? 'notTired' : 'wakeUp',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  notTired: {
    slug: 'notTired',
    image: img_31,
    question: 'Gehst du oft ins Bett, ohne müde zu sein?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'fear',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  fear: {
    slug: 'fear',
    image: img_32,
    question:
      'Hast du oft Angst davor ins Bett zu gehen, weil du befürchtest nicht einschlafen zu können?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'thoughts',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  thoughts: {
    slug: 'thoughts',
    image: img_33,
    question:
      'Liegst du oft noch länger im Bett und hast Schwierigkeiten, deine Gedanken abzuschalten?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'wakeUp',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  wakeUp: {
    slug: 'wakeUp',
    image: img_40,
    question:
      'Wachst du häufig ein- oder mehrmals pro Nacht auf und hast anschließend Schwierigkeiten, wieder einzuschlafen?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: (answerMap: Map<string, boolean | number | string>) =>
        answerMap.get('wakeUp') === true ? 'childrenPets' : 'wakeUpEarlier',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  childrenPets: {
    slug: 'childrenPets',
    image: img_41,
    question: 'Hast du Kinder oder Haustiere, die dich öfters vom Durchschlafen abhalten?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'toilet',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  toilet: {
    slug: 'toilet',
    image: img_42,
    question: 'Musst du häufiger nachts auf Toilette und wachst deswegen auf?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'hungerThirst',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  hungerThirst: {
    slug: 'hungerThirst',
    image: img_43,
    question: 'Bekommst du nachts regelmäßig Hunger oder Durst und wachst deswegen auf?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'externalInfluences',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  externalInfluences: {
    slug: 'externalInfluences',
    image: img_44,
    question:
      'Wirst du beim Schlafen häufiger durch äußere Einflüsse (Licht, Geräusche, usw) gestört?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'wakeUpEarlier',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  wakeUpEarlier: {
    slug: 'wakeUpEarlier',
    image: img_50,
    question:
      'Wachst du oft früher auf, als du willst und kommst dann nicht mehr so gut in den Schlaf?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: (answerMap: Map<string, boolean | number | string>) => {
        if (
          ((answerMap.get('quality') as number) > 35 && answerMap.get('hours') === '3-5') ||
          ((answerMap.get('quality') as number) > 35 && answerMap.get('hours') === '5-7') ||
          ((answerMap.get('quality') as number) > 35 && answerMap.get('hours') === '7-8') ||
          ((answerMap.get('quality') as number) > 35 && answerMap.get('hours') === 'mehr als 8')
        ) {
          return 'devices'
        }
        return 'threeWeeks'
      },
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  threeWeeks: {
    slug: 'threeWeeks',
    image: img_60,
    question: 'Hast du seit mehr als 3 Wochen Probleme mit deinem Schlaf?',
    category: category.SCHLAFSTOERUNGEN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'devices',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  devices: {
    slug: 'devices',
    image: img_70,
    question:
      'Benutzt du kurz vor dem Einschlafen oft Smartphone, Laptop, Tablet, PC oder Fernseher?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'sameTime',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  sameTime: {
    slug: 'sameTime',
    image: img_71,
    question: 'Gehst du grundsätzlich zur etwa gleichen Zeit ins Bett?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'caffeine',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  caffeine: {
    slug: 'caffeine',
    image: img_72,
    question:
      'Nimmst du bis zu 6 Stunden vor dem Schlafengehen noch koffeinhaltige Getränke zu dir?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'sweat',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  sweat: {
    slug: 'sweat',
    image: img_73,
    question: 'Schwitzt du oft in der Nacht?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'freeze',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  freeze: {
    slug: 'freeze',
    image: img_714,
    question: 'Frierst du oft in der Nacht?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'workInBedroom',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  workInBedroom: {
    slug: 'workInBedroom',
    image: img_74,
    question: 'Arbeitest du häufig in deinem Schlafzimmer oder Bett?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'physicallyActive',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  physicallyActive: {
    slug: 'physicallyActive',
    image: img_76,
    question: 'Treibst du mindestens 2 Stunden pro Woche schweißtreibenden Sport?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'meals',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  meals: {
    slug: 'meals',
    image: img_77,
    question: 'Nimmst du bis zu zwei Stunden vor dem Zubettgehen noch schwere Mahlzeiten ein?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'relaxation',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  relaxation: {
    slug: 'relaxation',
    image: img_78,
    question: 'Achtest du bereits bewusst auf Entspannung in deinem Alltag?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'routine',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  routine: {
    slug: 'routine',
    image: img_715,
    question: 'Beendest du den Tag mit einer bewussten Abendroutine?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'healthyFood',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  healthyFood: {
    slug: 'healthyFood',
    image: img_79,
    question: 'Achtest du regelmäßig auf eine ausgewogene und gesunde Ernährung?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'comfortable',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  comfortable: {
    slug: 'comfortable',
    image: img_710,
    question: 'Wachst du häufig mit Verspannungen oder Rückenschmerzen auf?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'alcohol',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  alcohol: {
    slug: 'alcohol',
    image: img_713,
    question: 'Trinkst du des öfteren vor dem Einschlafen alkoholische Getränke?',
    category: category.SCHLAFHYGIENE,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'measures',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  measures: {
    slug: 'measures',
    image: img_712,
    question: 'Welche der folgenden Maßnahmen hast du bereits für dich ausprobiert?',
    category: category.MASSNAHMEN,
    answer: {
      type: answerType.CHIPS,
      getNextStepSlug: () => 'children',
      options: [
        { label: 'Medikamente' },
        { label: 'Melatonin' },
        { label: 'Meditation' },
        { label: 'Podcasts' },
        { label: 'Hörbücher' },
        { label: 'Gesündere Ernährung' },
        { label: 'Neue Matratze' },
        { label: 'Neues Kissen' },
        { label: 'Gewichtsdecke' },
        { label: 'Supplements' },
        { label: 'Pflanzliche Mittel' },
        { label: 'Schlaftracker' },
        { label: 'Kein Handy im Bett' },
        { label: 'Nichts davon' }
      ]
    }
  },
  children: {
    slug: 'children',
    image: img_82,
    question: 'Hast du Kinder unter 10 Jahren',
    category: category.ALLGEMEIN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'mate',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  mate: {
    slug: 'mate',
    image: img_83,
    question: 'Hast du einen Partner, der schnarcht oder sich viel bewegt?',
    category: category.ALLGEMEIN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'shiftWork',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  shiftWork: {
    slug: 'shiftWork',
    image: img_84,
    question: 'Hast du Schichtdienst, der deinen Schlaf beeinflusst?',
    category: category.ALLGEMEIN,
    answer: {
      type: answerType.YES_NO,
      getNextStepSlug: () => 'age',
      options: [{ label: 'Ja' }, { label: 'Nein' }]
    }
  },
  age: {
    slug: 'age',
    image: img_80,
    question: 'Wie alt bist du?',
    category: category.ALLGEMEIN,
    answer: {
      type: answerType.RADIO,
      getNextStepSlug: () => 'gender',
      options: [
        { label: 'unter 18 Jahre' },
        { label: '18-24 Jahre' },
        { label: '25-34 Jahre' },
        { label: '35-44 Jahre' },
        { label: '45-54 Jahre' },
        { label: '55-65 Jahre' },
        { label: 'ab 65 Jahre' }
      ]
    }
  },
  gender: {
    slug: 'gender',
    image: img_81,
    question: 'Bitte verrate uns dein Geschlecht?',
    category: category.ALLGEMEIN,
    answer: {
      type: answerType.RADIO,
      getNextStepSlug: () => 'expectations',
      options: [{ label: 'weiblich' }, { label: 'männlich' }, { label: 'divers' }]
    }
  },
  expectations: {
    slug: 'expectations',
    image: img_90,
    question: 'Was erhoffst Du dir durch unseren Sleep-Check?',
    category: category.ALLGEMEIN,
    answer: {
      type: answerType.CHECKBOX,
      getNextStepSlug: () => 'emailScreen',
      options: [
        { label: 'Meinen Schlaf langfristig verbessern.' },
        { label: 'Benötige akut Einschlaf-Tipps.' },
        { label: 'Ein persönliches Gespräch zu meiner Situation.' },
        { label: 'Mich interessiert das Thema Schlaf allgemein.' },
        { label: 'Passende Produktempfehlungen.' }
      ]
    }
  }
}

export enum ScreenIDs {
  SLEEP_CHECK = 'sleepCheck',
  EMAIL_SCREEN = 'emailScreen',
  TESTER_REGISTRATION = 'testerRegistration'
}

export const screens = {
  [ScreenIDs.EMAIL_SCREEN]: {
    slug: ScreenIDs.EMAIL_SCREEN,
    image: img_85,
    text: 'Bitte trage deine E-Mail Adresse ein, um deine Ergebnisse zu erhalten.'
  },
  [ScreenIDs.TESTER_REGISTRATION]: {
    slug: ScreenIDs.TESTER_REGISTRATION,
    image: img_registration,
    text: 'xxx'
  }
}

export const START_SLUG = 'quality'
export const SECOND_SLUG = 'wellRested'
