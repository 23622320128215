import { IDatoCMSContentSection, InternalLinkBasePath } from '@lib/datocms/types/modelApiKeysEnum'
import { IDatoCMSCallToAction, IDatoCMSSelfHostedVideo } from '@lib/datocms/types/misc'
import { IProductSnippet } from '@modules/products/types'
import { ICallToAction, IInternalLink } from './types'

export const stripHtml = (html: string): string => html.replace(/<[^>]*>?/gm, '')

export const getExternalOrInternalLink = (
  link: IInternalLink | string,
  title = 'no title'
): string => {
  if (!link) {
    console.warn(`Warning: No link provided for ${title}`)
  }
  const model = (link as IInternalLink)?._modelApiKey
  const slug = (link as IInternalLink)?.slug
  const basePath = InternalLinkBasePath[model]
  if (basePath) return `${basePath}/${slug || ''}`
  return link as string
}

export const getLinkFromCallToAction = (callToAction: ICallToAction): string => {
  if (callToAction?.internalLink) {
    return getExternalOrInternalLink(callToAction.internalLink)
  }
  if (callToAction?.externalLink) {
    return callToAction.externalLink
  }
  return ''
}

export const getTargetForCta = (
  link: IDatoCMSCallToAction, // todo: to be typed after CTA content section is mapped
  contentSections: IDatoCMSContentSection[]
): string | null => {
  if (!link?.buttonText) return '#'
  if (contentSections.length === 0) return '#'

  const firstContentSection =
    contentSections[0]._modelApiKey !== 'divider' ? contentSections[0].id : contentSections[1].id

  const hasValidContentSectionIndex =
    link.targetContentSection && link.targetContentSection <= contentSections.length

  if (hasValidContentSectionIndex) {
    const contentSectionIndex = link.targetContentSection - 1
    return contentSections[contentSectionIndex].id
  }
  return `${firstContentSection}`
}

export const isVideoLandscape = (video: IDatoCMSSelfHostedVideo): boolean =>
  video.video && video.width > video.height

export const isVideoPortrait = (video: IDatoCMSSelfHostedVideo): boolean =>
  video.video && video.width < video.height

export const getImageFormat = (image): string => {
  if (image.width < image.height) {
    return 'portraitInstagram'
  }
  if (image.width === image.height) {
    return 'square'
  }
  if (image.width > image.height) {
    return 'landscape'
  }
  return null
}

export const getNumberOfDesktopSlides = (imgFormat) => {
  if (imgFormat === 'portraitInstagram') {
    return 4
  }
  if (imgFormat === 'square') {
    return 3
  }
  if (imgFormat === 'landscape') {
    return 2
  }
  return null
}

// only for CTA content section to be used until cs refactoring is completed

export const getImagesAndLinksForProductLists = (imageType: string, product: IProductSnippet) => {
  const imageToShow = imageType === 'cropped' ? product.croppedImage : product.firstImage
  const linkWithHash =
    imageType === 'cropped'
      ? `/products/${product.shopifyProductHandle}#cropped`
      : `/products/${product.shopifyProductHandle}`

  return { imageToShow, linkWithHash }
}

export const getPosterUrl = (video: IDatoCMSSelfHostedVideo): string => {
  if (video.customData?.customThumbnail) return video.customData.customThumbnail
  if (video.customData?.time) return `${video.video.thumbnailUrl}${video.customData.time}`
  return video.video.thumbnailUrl
}
