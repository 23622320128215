import React, { useContext } from 'react'

import ShoppingBagButton from '@components/Layout/Header/Navigation/ShoppingBagButton'
import SearchContainer from '@modules/search/SearchContainer'
import SearchContext from '@context/SearchContext'
import BurgerMenu from '@components/Layout/Header/Navigation/BurgerMenu/BurgerMenu'
import SearchButton from '@components/Layout/Header/Navigation/SearchButton'
import Logo from '@components/Layout/Header/Navigation/Logo/Logo'
import AccountButton from '@components/Layout/Header/Navigation/AccountButton/AccountButton'
import { IMenuItem } from '@modules/menu/types'
import { Menu } from '@components/Layout/Header/Navigation/Menu'
import UiContext from '@context/UiContext'
import classNames from 'classnames'

interface INavigation {
  topMenu: IMenuItem[]
}

const Navigation: React.FC<INavigation> = ({ topMenu }) => {
  const search = useContext(SearchContext)
  const { closeMobileMenu, desktopMenuOpen } = useContext(UiContext)
  const searchButtonReference = React.useRef(null)

  const { isOpen, setIsOpen } = search

  return (
    <nav
      id="menu"
      className={classNames('w-full border-b border-gray-300 relative min-h-[57px]', {
        'border-white': desktopMenuOpen
      })}>
      <div className="section-content-width flex flex-wrap items-center justify-between bg-white z-1 relative md:static border-gray-300 border-b md:border-white">
        <div className="flex items-center">
          <BurgerMenu />
          <Logo />
        </div>
        <div className="flex justify-end xl:w-[100px]" onClick={closeMobileMenu}>
          <SearchButton buttonRef={searchButtonReference} />
          <AccountButton />
          <ShoppingBagButton />
        </div>
      </div>

      <SearchContainer
        iconReference={searchButtonReference}
        isSearchOpen={isOpen}
        setSearchVisibility={setIsOpen}
      />
      <Menu topMenu={topMenu} />
    </nav>
  )
}

export default Navigation
