import React from 'react'
import { IShopifyLineItem } from '@lib/shopify/interfaces'
import CouponTag from '@components/icons/CouponTag'
import { VALID_DISCOUNT_TARGET_SELECTIONS } from '@lib/shopify/constants'
import { shopifyToEuro } from '../../utils'

interface IProps {
  lineItem: IShopifyLineItem
}

const getDiscountLabel = (allocation): string =>
  `${allocation.discountApplication.title || allocation.discountApplication.code} (-${shopifyToEuro(
    Number(allocation.allocatedAmount.amount)
  )})`

const LineItemDiscount: React.FC<IProps> = (props: IProps) => {
  const { lineItem } = props

  return (
    <>
      {lineItem.discountAllocations
        .filter((allocation) =>
          VALID_DISCOUNT_TARGET_SELECTIONS.includes(allocation.discountApplication.targetSelection)
        )
        .map((allocation) => (
          <div className="flex items-center mt-2" key={allocation.discountApplication.title}>
            <span>
              <CouponTag />
            </span>
            <span className="uppercase text-xs md:text-sm tracking-wide ml-2">
              {getDiscountLabel(allocation)}
            </span>
          </div>
        ))}
    </>
  )
}

export default LineItemDiscount
