import React, { useContext } from 'react'

import classNames from 'classnames'
import UiContext from '@context/UiContext'
import { IMenuItem } from '@modules/menu/types'
import TopMenuItem from './TopMenuItem'
import MenuSlide from './MenuSlide'

type MenuProps = {
  topMenu: IMenuItem[]
}

const Menu = ({ topMenu }: MenuProps) => {
  const { mobileMenuOpen, desktopMenuOpen, setDesktopMenuOpen } = useContext(UiContext)

  return (
    <div
      className={classNames(
        'overflow-x-hidden bg-white flex flex-col h-[calc(100vh-56px)] transition-margin duration-300 ease-in-out md:mt-0 md:h-auto md:bg-transparent',
        { '-mt-[calc(100vh+80px)] md:flex': !mobileMenuOpen }
      )}>
      <ul
        onMouseLeave={() => {
          setDesktopMenuOpen(false)
        }}
        className="flex flex-wrap bg-gray-300 px-4 py-2 justify-between md:flex-nowrap md:absolute md:top-1 md:m-auto md:left-1/2 md:-translate-x-1/2 md:pb-4 md:bg-transparent">
        {topMenu.map((item, index) => (
          <React.Fragment key={item.id}>
            {index === Math.round(topMenu.length / 2) && <li className="w-full md:hidden" />}
            <TopMenuItem item={item} />
          </React.Fragment>
        ))}
      </ul>
      <ul
        id="menu-scroll-area"
        key="menu" // fix for iOs, otherwise the menu keeps its scroll position when changing pages
        className={classNames(
          'w-full flex bg-white overflow-x-auto overflow-y-hidden no-scrollbar snap-x snap-mandatory text-sm font-bold grow transition-all mt-0 lg:text-base md:bg-transparent md:absolute md:z-0',
          { 'md:flex': desktopMenuOpen, 'md:hidden': !desktopMenuOpen }
        )}>
        {topMenu.map((menuItem) => (
          <MenuSlide menuItem={menuItem} key={menuItem.id} />
        ))}
      </ul>
    </div>
  )
}

export default Menu
