import React, { useContext } from 'react'
import Image from 'next/image'

import { IMenuHighlightItem } from '@modules/menu/types'
import Link from 'next/link'
import { getExternalOrInternalLink } from '@modules/common/utils'
import classNames from 'classnames'
import UiContext from '@context/UiContext'

type MenuHighlightProps = {
  highlight: IMenuHighlightItem
  itemsLength: number
}

const MenuHighlight = ({ highlight, itemsLength }: MenuHighlightProps) => {
  const { closeMobileMenu } = useContext(UiContext)

  return (
    <li
      key={highlight.id}
      className={classNames('px-1 md:px-2 md:w-40 xl:w-56', {
        'w-1/3': itemsLength === 3 || itemsLength === 6,
        'w-1/2': itemsLength === 2 || itemsLength === 4,
        'w-full': itemsLength === 1
      })}>
      {highlight.link && (
        <Link legacyBehavior prefetch={false} href={getExternalOrInternalLink(highlight.link)}>
          <a onClick={closeMobileMenu}>
            <Image
              src={highlight.image.responsiveImage.src}
              alt={highlight.title}
              width={highlight.image.responsiveImage.width}
              height={highlight.image.responsiveImage.height}
              sizes="(max-width: 767px) 100vw, 200px"
            />
            <span className="my-1 block font-normal overflow-ellipsis overflow-hidden">
              {highlight.title}
            </span>
          </a>
        </Link>
      )}
    </li>
  )
}

export default MenuHighlight
