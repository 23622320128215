import React, { useContext } from 'react'

import { IShopifyLineItem } from '@lib/shopify/interfaces'
import SpinnerIcon from '@components/icons/Spinner'
import Item from './ShoppingCart/Item'
import ShopContext from '../../../context/ShopContext'

interface IShoppingCart {
  interactive: boolean
  loading: boolean
}

const ShoppingCart: React.FC<IShoppingCart> = ({ interactive, loading }) => {
  const { checkout } = useContext(ShopContext)

  if (checkout.lineItems.length === 0 && !loading) {
    return (
      <div className="text-center text-gray-300 font-bold text-lg">
        <span className="block px-16 py-20">Noch kein Produkt im Warenkorb</span>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="px-4 sm:px-6 pb-4 divide-y">
        <div className="relative min-h-[110px]">
          <div className="absolute inset-1/2">
            <SpinnerIcon />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="px-4 sm:px-6 pb-4 divide-y">
      {checkout.lineItems.map((lineItem: IShopifyLineItem) => (
        <Item lineItem={lineItem} interactive={interactive} key={lineItem.id} />
      ))}
    </div>
  )
}

export default ShoppingCart
