import React from 'react'

import { Step } from '@modules/sleepcheck/types'
import Input from './YesNo/Input'

interface IYesNo {
  step: Step
  setCurrentValue: (value: boolean) => void
  onSubmit: ({ step, currentValue }) => void
}

const YesNo: React.FC<IYesNo> = ({ step, setCurrentValue, onSubmit }) => {
  const [checkedId, setCheckedId] = React.useState(null)
  const handleClick = (target: HTMLInputElement) => {
    const label = target.value === 'true' ? 'Ja' : 'Nein'
    const currentValue = target.value === 'true'
    setCheckedId(`${step.slug}-${label}`)
    setCurrentValue(currentValue)
    onSubmit({
      step,
      currentValue
    })
  }

  return (
    <div className="-mx-2 my-6 flex">
      {step.answer.options.map((option) => {
        const id = `${step.slug}-${option.label}`
        return (
          <Input
            id={id}
            option={option}
            step={step}
            key={id}
            onClick={handleClick}
            isChecked={id === checkedId}
          />
        )
      })}
    </div>
  )
}

export default YesNo
