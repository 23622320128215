import selectInfoFromCartProducts from '@context/selectors'
import { IShopifyLineItem } from '@lib/shopify/interfaces'
import { ICartTrackingData } from '@lib/tracking/trackAddToCartEvent'
import { ICartItem, IProduct, IProductVariant } from '@modules/products/types'

export const mapVariantProductToTrackingData = (
  variant: IProductVariant,
  product: IProduct,
  quantity: number
): ICartTrackingData => ({
  brand: product.brand.name,
  itemId: variant.sku,
  itemName: product.name,
  price: variant.price,
  quantity
})

export const mapShopifyLineItemToTrackingData = (
  lineItem: IShopifyLineItem,
  cartItems: ICartItem[],
  quantity = 1
): ICartTrackingData => {
  const { brand } = selectInfoFromCartProducts(cartItems, lineItem)
  return {
    brand,
    itemId: lineItem.variant.sku,
    itemName: lineItem.title,
    price: lineItem.variant.price.amount,
    quantity
  }
}
