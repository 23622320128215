export const LOCAL_STORAGE_KEY = `shopify_checkout_id`
export const DEFAULT_DELIVERY_TIME = '2-4 Tage'

export const shippingThreshold = 49
export const shippingCost = '3,95 €'

export const defaultListSize = 8

export const SLEEPCHECK_ANSWERMAP_LOCAL_STORAGE_KEY = `sleepcheck_answermap`
export const SLEEPCHECK_COOKIE_REFERENCE_KEY = 'yoself-sleepcheck-reference'
