import React from 'react'
import { AppProps } from 'next/app'

import '../styles/globals.css'

import ErrorBoundary from '@components/ErrorBoundary'
import { UiContextProvider } from '@context/UiContext'
import { ShopContextProvider } from '@context/ShopContext'
import { UserContextProvider } from '@context/UserContext'
import { SearchContextProvider } from '@context/SearchContext'
import { SleepCheckContextProvider } from '@context/SleepCheckContext'
import { SleepProgramContextProvider } from '@context/SleepProgramContext'
import TrackingScript from '@components/Layout/tracking/TrackingScript'
import { referenzGroteskYoself } from '../fonts/localFont'

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // TODO: fix properly
  const Content = Component

  return (
    <>
      <TrackingScript />
      <ErrorBoundary topMenu={pageProps.topMenu}>
        <UserContextProvider>
          <SleepCheckContextProvider>
            <SleepProgramContextProvider>
              <ShopContextProvider productMap={pageProps.productsBySlug}>
                <UiContextProvider>
                  <SearchContextProvider>
                    <>
                      {/* eslint-disable-next-line react/no-unknown-property */}
                      <style jsx global>{`
                        html {
                          font-family: ${referenzGroteskYoself.style.fontFamily};
                        }
                      `}</style>
                      <Content {...pageProps} />
                    </>
                  </SearchContextProvider>
                </UiContextProvider>
              </ShopContextProvider>
            </SleepProgramContextProvider>
          </SleepCheckContextProvider>
        </UserContextProvider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
