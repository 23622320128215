import { ICartItem } from '@modules/products/types'

export const ADD_CART_ITEMS = 'ADD_CART_ITEMS'

type ICartProductState = ICartItem[]

type ICartProductAction = {
  type: string
  payload: {
    cartItems: ICartItem[]
  }
}
const initialState: ICartProductState = []

const cartItemReducer = (
  // eslint-disable-next-line default-param-last
  state: ICartProductState = initialState,
  action: ICartProductAction
): ICartProductState => {
  switch (action.type) {
    case ADD_CART_ITEMS:
      return [...state, ...action.payload.cartItems]
    default: {
      return state
    }
  }
}

export default cartItemReducer
