import { IShopifyCartProduct, IShopifyCartProductsQueryResponse } from '@lib/shopify/interfaces'

export default class CartAPIParser {
  static parseProductQueryResponse(data: IShopifyCartProductsQueryResponse): IShopifyCartProduct[] {
    return data.nodes.map((product) => ({
      id: product.id,
      vendor: product.vendor,
      tags: product.tags,
      metafields:
        product.metafields
          ?.filter((metafield) => metafield !== null)
          ?.map((metafield) => ({
            id: metafield.id,
            key: metafield.key,
            value: metafield.value
          })) || [],
      variants: product.variants.edges.map((variant) => ({
        id: variant.node.id,
        sku: variant.node.sku,
        quantityAvailable: variant.node.quantityAvailable,
        availableForSale: variant.node.availableForSale,
        currentlyNotInStock: variant.node.currentlyNotInStock,
        metafields:
          variant.node.metafields
            ?.filter((metafield) => metafield !== null)
            ?.map((metafield) => ({
              id: metafield.id,
              key: metafield.key,
              value: metafield.value
            })) || []
      }))
    }))
  }
}
