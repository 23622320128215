import React from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'

interface QuantitySelectProps {
  handleChange: (value) => void
  value: number
  min: number
  max: number
}

function getQuantityRange(min = 1, max = 10) {
  const options = []
  for (let i = min; i <= max; i++) {
    options.push(i)
  }

  return options
}

const QuantitySelect = ({ value, handleChange, min, max }: QuantitySelectProps) => {
  const options = getQuantityRange(min, max)

  return (
    <Listbox value={value} onChange={handleChange} aria-label="Anzahl ändern">
      <div className="relative w-24">
        <Listbox.Button className="relative w-full py-2.5 pl-4 pr-10 text-left bg-white border-2 border-black rounded focus:ring-0">
          <span className="block truncate">{value}x</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Listbox.Options className="z-50 absolute w-full py-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((v) => (
              <Listbox.Option
                key={v}
                className={({ active }) =>
                  classnames(
                    'quantity-select-value cursor-default select-none relative py-2 pl-10 pr-4',
                    {
                      'text-gray-600 bg-gray-200': active,
                      'text-gray-900': !active
                    }
                  )
                }
                value={v}>
                {v}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default QuantitySelect
