import { ArrowUpIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { useState, useEffect } from 'react'

const ScrollToTopButton: React.FC = () => {
  const [y, setY] = useState(0)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [isProductPage, setIsProductPage] = useState(false)

  const detectScrollingUp = (e) => {
    if (y > window.scrollY && window.scrollY > 100) {
      setIsScrollingUp(true)
    } else if (y < window.scrollY) {
      setIsScrollingUp(false)
    }
    setY(e.currentTarget.scrollY)
  }

  useEffect(() => {
    setY(window.scrollY)

    window.addEventListener('scroll', (e) => detectScrollingUp(e))
  }, [y])

  const handleCllick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const router = useRouter()
  useEffect(() => {
    if (router.asPath.includes('/products')) {
      setIsProductPage(true)
    } else {
      setIsProductPage(false)
    }
  }, [router.asPath])

  return (
    <AnimatePresence>
      {isScrollingUp && (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classNames(
            'fixed bg-black w-10 h-10 lg:w-12 lg:h-10 flex-grow right-0 bottom-0 z-10 p-2 lg:p-3 rounded',
            {
              'mb-20 lg:mb-28 mr-3 sm:mr-6 lg:mr-8 xl:mr-28': isProductPage,
              'm-6 lg:m-10': !isProductPage
            }
          )}
          type="button"
          onClick={handleCllick}>
          <ArrowUpIcon stroke="white" className="m-auto w-2/3" />
        </motion.button>
      )}
    </AnimatePresence>
  )
}

export default ScrollToTopButton
