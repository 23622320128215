import { toast, Toaster } from 'react-hot-toast'
import React from 'react'

const Toast: React.FC = () => (
  <Toaster
    position="top-center"
    toastOptions={{
      className: 'mt-7',
      style: {
        background: '#000',
        color: '#fff'
      },
      icon: '🏷️',
      success: {
        duration: 4500
      }
    }}
  />
)

export const createToast = (
  message: string,
  type: 'success' | 'error' | 'warning' | 'info'
): void => {
  toast[type](message)
}

export default Toast
