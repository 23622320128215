import React, { useContext, useState, useRef, useEffect } from 'react'
import { motion, useInView } from 'framer-motion'
import { IMenuItem } from '@modules/menu/types'
import UiContext from '@context/UiContext'
import MenuHighlight from '@components/Layout/Header/Navigation/Menu/MenuHighlight'
import CollapsibleMenuItem from './CollapsibleMenuItem'

type MenuSlideProps = {
  menuItem: IMenuItem
}

const MenuSlide = ({ menuItem }: MenuSlideProps) => {
  const [openIndex, setOpenIndex] = useState(0)
  const { setActiveMenuSlide, setDesktopMenuOpen } = useContext(UiContext)

  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 0.6 })

  useEffect(() => {
    if (isInView) setActiveMenuSlide(ref.current.id)
  }, [isInView])

  return (
    <motion.li ref={ref} id={menuItem.id}>
      {menuItem.children && menuItem.children.length > 0 && (
        <ul
          onFocus={() => {
            setDesktopMenuOpen(true)
          }}
          onMouseOver={() => {
            setDesktopMenuOpen(true)
          }}
          onMouseLeave={() => {
            setDesktopMenuOpen(false)
          }}
          className="flex flex-col bg-white w-screen snap-always snap-start h-full overflow-y-auto md:flex-row md:h-auto md:max-h-[calc(100vh-200px)] md:justify-center md:border-gray-300 md:border-b">
          {menuItem.children.map((child, index) => (
            <CollapsibleMenuItem
              menuItem={child}
              index={index}
              open={index === openIndex}
              setOpenIndex={setOpenIndex}
              openIndex={openIndex}
              key={child.id}
              level={1}
            />
          ))}
          {menuItem.highlights && menuItem.highlights.length > 0 && (
            <li className="w-full -mt-[1px] md:w-auto md:px-8 md:pt-1 md:pb-4 lg:px-16">
              <div className="uppercase pt-4 px-4">Highlights</div>
              <ul className="flex flex-wrap justify-between pt-4 px-3 pb-24 md:max-w-lg">
                {menuItem.highlights.map((highlight) => (
                  <MenuHighlight
                    highlight={highlight}
                    itemsLength={menuItem.highlights.length}
                    key={highlight.id}
                  />
                ))}
              </ul>
            </li>
          )}
        </ul>
      )}
    </motion.li>
  )
}

export default MenuSlide
