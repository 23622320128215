import { IShopifyProduct, IShopifyProductVariant } from '@lib/shopify/interfaces'
import { IDatoCMSVariantImage } from '@lib/datocms/types/misc'
import { IProductVariant, StockStatus } from '@modules/products/types'

export const selectPrice = (variant: IShopifyProductVariant): string => variant.price.amount

export const selectRegularPrice = (variant: IShopifyProductVariant): string =>
  variant.compareAtPrice?.amount || variant.price.amount

export const getStockStatus = (variant: IShopifyProductVariant): StockStatus => {
  if (variant.currentlyNotInStock === true) {
    return StockStatus.onbackorder
  }

  if (variant.quantityAvailable > 0 || variant.availableForSale) {
    return StockStatus.instock
  }

  return StockStatus.outofstock
}

export const selectDeliveryTime = (
  variant: IShopifyProductVariant,
  product: IShopifyProduct
): string => {
  if (product.deliveryTime) {
    return product.deliveryTime.value
  }
  return variant.deliveryTime?.value || null
}

export const selectAvailabilityDate = (
  variant: IShopifyProductVariant,
  product: IShopifyProduct
): string => {
  if (product.availabilityDate) {
    return product.availabilityDate.value
  }
  return variant.availabilityDate?.value || null
}

export default function mapShopifyVariant(
  productVariant: IShopifyProductVariant,
  product: IShopifyProduct,
  variantImage: IDatoCMSVariantImage
): IProductVariant {
  return {
    availabilityDate: selectAvailabilityDate(productVariant, product),
    deliveryTime: selectDeliveryTime(productVariant, product),
    name: productVariant.title,
    tags: product.tags,
    price: selectPrice(productVariant),
    regularPrice: selectRegularPrice(productVariant),
    sku: productVariant.sku,
    stockStatus: getStockStatus(productVariant),
    shopifyVariantId: productVariant.id,
    images: variantImage ? variantImage.images : []
  }
}
