import React from 'react'

import { IMenuItem } from '@modules/menu/types'
import Navigation from './Navigation'

interface IHeader {
  topMenu: IMenuItem[]
}

const Header: React.FC<IHeader> = ({ topMenu }) => (
  <header className="z-30 sticky top-0 bg-white">
    <Navigation topMenu={topMenu} />
  </header>
)

export default Header
