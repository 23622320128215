import React from 'react'
import Link from 'next/link'
import Container from './Container'

const Alert: React.FC = () => (
  <div className="border-bbg-accent-7 border-accent-7 text-white bg-black">
    <Container>
      <div className="py-2 text-center text-sm">
        This is page is showing draft content.{' '}
        <Link href="/api/exit-preview">
          <a className="underline hover:text-cyan duration-200 transition-colors">Click here</a>{' '}
        </Link>
        to exit preview mode.
      </div>
    </Container>
  </div>
)

export default Alert
