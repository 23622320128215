import algoliasearch, { SearchIndex } from 'algoliasearch'
import { ISearchItems, ISearchResult } from './interfaces'
import mapSearchResults from './mapSearchResults'

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_TOKEN
)
const algoliaSearchIndex = algoliaClient.initIndex(process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX)

export default class SearchClient {
  private searchIndex: SearchIndex

  constructor(index = algoliaSearchIndex) {
    this.searchIndex = index
  }

  public async search(query: string): Promise<ISearchResult> {
    const responseData = await this.searchIndex.search<ISearchItems>(query, { hitsPerPage: 300 })
    return mapSearchResults(responseData)
  }
}
