import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { AnswerOption, Step } from '@modules/sleepcheck/types'
import Checkbox from '@components/SleepCheck/CheckboxGroup/Checkbox'
import classNames from 'classnames'

type CheckboxGroupProps = {
  step: Step
  setCurrentValue: (value: string[]) => void
}

const CheckboxGroup = ({ step, setCurrentValue }: CheckboxGroupProps) => {
  const [checkedInputs, setCheckedInputs] = useState([])
  const [shuffeldOptions, setShuffeldOptions] = useState([])
  const getId = (option: AnswerOption) => `${step.slug}-${option.label}`
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target

    if (checked) {
      setCheckedInputs([...checkedInputs, value])
    } else {
      setCheckedInputs(checkedInputs.filter((item) => item !== value))
    }
  }

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value.length) {
      setCheckedInputs([
        ...checkedInputs.filter((item) => !item.startsWith('Sonstiges:')),
        `Sonstiges: ${value}`
      ])
    } else {
      setCheckedInputs([...checkedInputs.filter((item) => !item.startsWith('Sonstiges:'))])
    }
  }

  const shuffle = (array: AnswerOption[]) => array.sort(() => Math.random() - 0.5)

  useEffect(() => {
    setCurrentValue(checkedInputs) // if not spread, the array is not updated, react does not rerender
  }, [checkedInputs])

  useEffect(() => {
    setShuffeldOptions(shuffle(step.answer.options))
  }, [])

  return (
    <div className="my-6 flex flex-col w-full">
      {shuffeldOptions.map((option, index) => {
        const id = getId(option)

        return (
          <Checkbox
            key={id}
            option={option}
            step={step}
            id={id}
            handleChange={handleChange}
            isFirst={index === 0}
            isLast={step.answer.options.length - 1 === index}
          />
        )
      })}
      <p className="mt-4 text-gray-600 text-sm outline-0">Sonstiges:</p>
      <input
        ref={ref}
        type="text"
        onChange={handleTextChange}
        className={classNames('rounded border-2 text-gray-600', {
          'border-black': ref.current?.value.length,
          'border-gray-300': !ref.current?.value.length
        })}
      />
    </div>
  )
}

export default CheckboxGroup
