import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { IProductSearchItem } from '@lib/SearchIndexService/types'
import SearchContext from '@context/SearchContext'
import { toEuro } from '../../utils'

interface ProductSearchResultsProps {
  pagination: number
  products: IProductSearchItem[]
}

const ProductSearchResults = ({ pagination, products }: ProductSearchResultsProps) => {
  const search = React.useContext(SearchContext)
  return (
    <div className="md:pr-12 xl:pr-20 pb-8">
      <ul className="list-none w-full">
        {products.slice(0, pagination).map((product) => (
          <li key={product.objectID} className="my-6">
            <Link legacyBehavior href={product.link}>
              <a href={product.link} onClick={() => search.setIsOpen(false)}>
                <div className="flex items-center">
                  <div className="w-1/3 shrink-0 group">
                    <div className="relative w-full aspect-w-4 aspect-h-3">
                      {product.image && (
                        <Image
                          alt={product.name}
                          className="md:group-hover:scale-105 transition-all object-cover"
                          src={product.image.src}
                          fill
                          style={{ objectPosition: product.image.objectPosition }}
                          sizes="(min-width: 767px) 20vw, 100vw"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col pl-5">
                    <p className="uppercase text-xs md:text-sm">{product.brand}</p>
                    <h2 className="font-bold text-sm lg:text-base mt-1 hover:underline">
                      {product.name}
                    </h2>
                    <p className="mt-1">{toEuro(parseFloat(product.price))}</p>
                  </div>
                </div>
              </a>
            </Link>
          </li>
        ))}
      </ul>
      {/* {showMoreProducts ? } */}
    </div>
  )
}

export default ProductSearchResults
