import React, { useContext } from 'react'
import { ShoppingBagIcon } from '@heroicons/react/24/outline'

import ShopContext from '@context/ShopContext'
import Link from 'next/link'

const ShoppingBagButton: React.FC = () => {
  const shopContext = useContext(ShopContext)
  const { checkout } = shopContext
  const isShoppingBagFilled: boolean = checkout.lineItems.length > 0

  return (
    <Link legacyBehavior href="#cart">
      <a
        aria-label="Warenkorb"
        className="relative flex justify-center items-center focus:outline-none">
        <ShoppingBagIcon className="h-6 w-8" />

        {isShoppingBagFilled && (
          <div className="absolute bottom-0 right-0 block h-4 w-4 rounded-full bg-green-600 mb-0.5 mr-0.5 border-2 border-white" />
        )}
      </a>
    </Link>
  )
}

export default ShoppingBagButton
