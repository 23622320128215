import React, { useContext, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'

import { ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { hasGenericDiscount } from '@lib/shopify/utils'
import UiContext from '@context/UiContext'
import TaxAndShippingText from '@components/TaxAndShippingText'
import ShoppingCartNotes from '@components/ShoppingCartNotes'
import ShopContext from '@context/ShopContext'
import trackBeginCheckoutEvent from '@lib/tracking/trackBeginCheckoutEvent'
import SpinnerIcon from '@components/icons/Spinner'
import ShoppingCart from '@modules/checkout/components/ShoppingCart'
import PaymentOptions from '@components/ShoppingCartNotes/PaymentOptions'
import { shopifyToEuro } from '../../../../utils'
import DiscountSubtotal from './DiscountSubtotal'

const SlideOver: React.FC = () => {
  const [isCheckoutUrlPending, setCheckingUrlPending] = React.useState(false)
  const { checkout, cartItems, loading, getCheckoutURL } = useContext(ShopContext)
  const { showShoppingCart } = useContext(UiContext)
  const router = useRouter()
  const handleCheckout = async () => {
    trackBeginCheckoutEvent(checkout.lineItems, cartItems)
    try {
      setCheckingUrlPending(true)
      const checkoutURL = await getCheckoutURL()
      await router.push(checkoutURL)

      // fix for mobile safari
      setTimeout(() => {
        setCheckingUrlPending(false)
      }, 1500)
    } catch (err) {
      console.error(err)
      setCheckingUrlPending(false)
    }
  }
  const showDiscountCalculation = hasGenericDiscount(checkout) && checkout.lineItems.length > 0

  useEffect(() => {
    setCheckingUrlPending(false)
  }, [showShoppingCart])

  return (
    <AnimatePresence>
      {showShoppingCart && (
        <motion.div
          className="inset-0 overflow-hidden dialog-background flex"
          role="dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}>
          <Link legacyBehavior href="#_">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a className="grow" />
          </Link>
          <motion.div
            className="inset-y-0 right-0 max-w-full flex"
            initial={{ x: '100%' }}
            animate={{ x: '0' }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.4 }}>
            <div className="w-screen max-w-lg">
              <div className="h-full flex flex-col bg-white shadow-bottom overflow-y-scroll">
                <div className="px-4 sm:px-6 sticky z-1 top-0 bg-white py-4 md:pt-6 md:pb-4 border-b">
                  <div className="flex items-start justify-between">
                    <h2 className="flex-grow-1 text-base md:text-xl font-extrabold text-gray-900 uppercase">
                      Warenkorb
                    </h2>
                    <div className="h-7 flex ml-auto items-center">
                      <Link legacyBehavior href="#_">
                        <a className="bg-white rounded-md focus:outline-none">
                          <span className="sr-only">Warenkorb schließen</span>

                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:mt-6 relative flex-1">
                  <ShoppingCart interactive loading={loading} />
                </div>
                <div className="bg-white bottom-0 sticky pt-6 pb-6 w-full shadow-top">
                  <ShoppingCartNotes checkoutTotalPrice={Number(checkout.totalPrice.amount)} />
                  <div className="flex flex-wrap justify-center px-4 sm:px-6 items-center">
                    {showDiscountCalculation && (
                      <div className="w-full">
                        <DiscountSubtotal checkout={checkout} />
                      </div>
                    )}

                    <div className="w-full flex flex-col justify-between md:mt-2">
                      <div className="flex flex-wrap w-full text-base md:text-xl font-bold">
                        <div className="rounded">Gesamtsumme:</div>
                        <div className="ml-auto rounded">
                          {shopifyToEuro(Number(checkout.totalPrice.amount))}
                        </div>
                      </div>
                      <div className="mb-3 -mt-2">
                        <TaxAndShippingText totalPrice={Number(checkout.totalPrice.amount)} />
                      </div>
                      <button
                        className="btn btn--black whitespace-nowrap disabled:opacity-25 hover:text-white hover:bg-black flex justify-center"
                        disabled={checkout.lineItems.length === 0}
                        type="button"
                        onClick={handleCheckout}>
                        {!isCheckoutUrlPending && <ShoppingBagIcon className="h-6 w-6" />}
                        {isCheckoutUrlPending && <SpinnerIcon size={24} />}
                        <span>Weiter zur Kasse</span>
                      </button>
                    </div>
                  </div>
                  <PaymentOptions />
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SlideOver
