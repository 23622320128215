import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { ICategorySearchItem } from '@lib/SearchIndexService/types'
import SearchContext from '@context/SearchContext'

interface CategorySearchResultsProps {
  pagination: number
  categories: ICategorySearchItem[]
}

const CategoriesSearchResults = ({ pagination, categories }: CategorySearchResultsProps) => {
  const search = React.useContext(SearchContext)
  return (
    <div className="flex flex-wrap mt-8 -ml-2 pb-9 md:pb-2 md:pr-20">
      {categories.slice(0, pagination).map((category) => (
        <div className="w-1/2 md:w-1/3" key={category.objectID}>
          <div className="mx-2">
            <Link legacyBehavior href={category.link}>
              <a className="group" onClick={() => search.setIsOpen(false)}>
                <div className="relative block w-full aspect-w-4 aspect-h-3">
                  {category.image.responsiveImage && (
                    <Image
                      alt={category.name}
                      src={category.image.responsiveImage.src}
                      fill
                      className="mix-blend-multiply object-cover"
                      sizes="(min-width: 767px) 25vw, 50vw"
                    />
                  )}
                </div>
                <h2 className="font-bold text-sm my-2 break-words hover:underline">
                  {category.name}
                </h2>
              </a>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CategoriesSearchResults
