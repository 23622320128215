import React from 'react'
import { AnswerOption, Step } from '@modules/sleepcheck/types'

interface IInput {
  id: string
  option: AnswerOption
  step: Step
  onClick: (target: EventTarget) => void
  isChecked: boolean
}

const Input: React.FC<IInput> = ({ id, option, step, onClick, isChecked }) => (
  <div className="w-1/2">
    <input
      type="radio"
      className="peer hidden"
      id={id}
      name={step.slug}
      checked={isChecked}
      value={option.label === 'Ja' ? 'true' : 'false'}
      onClick={(e) => onClick(e.target)}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
    />
    <label
      className="flex h-12 mx-2 rounded border-2 border-gray-300 justify-center items-center cursor-pointer peer-checked:bg-gray-200 peer-checked:border-black"
      htmlFor={id}>
      {option.label}
    </label>
  </div>
)

export default Input
