import React, { useContext, useRef } from 'react'
import Link from 'next/link'
import { toast } from 'react-hot-toast'

import UserContext from '@context/UserContext'
import CheckCircleSolidIcon from '@components/icons/CheckCircleSolidIcon'
import SleepCheckContext from '@context/SleepCheckContext'
import appUrl from '@utils/appUrl'
import { UserIcon } from '@heroicons/react/24/outline'

const AccountButton = () => {
  const { auth, logout } = useContext(UserContext)
  const { user } = auth
  const { result } = useContext(SleepCheckContext)
  const { isAuthenticated: isLoggedIn } = auth
  const dropDownButton = useRef<HTMLDivElement>(null)

  const handleLogout = () => {
    logout()
    toast('Du hast dich erfolgreich abgemeldet.', { icon: '✅' })
  }
  return (
    <div ref={dropDownButton} className="relative group w-8 flex items-center justify-center">
      <button type="button" className="relative" aria-label="Benutzer">
        <UserIcon className="h-6 w-6" />
        {isLoggedIn && (
          <div className="absolute -bottom-1 -right-1.5 bg-white z-10 rounded-full w-4 h-4 border-r-2 border-white">
            <CheckCircleSolidIcon />
          </div>
        )}
      </button>
      <div className="absolute hidden justify-start flex-col bg-white rounded px-3 py-2 whitespace-nowrap -right-8 top-full shadow-bottom z-50 group-hover:flex group-focus-within:flex">
        {isLoggedIn && (
          <Link href="/user/account" className="py-2">
            Mein Profil
          </Link>
        )}
        {isLoggedIn && (
          <button className="py-2 text-left" type="button" onClick={handleLogout}>
            Logout
          </button>
        )}
        {!isLoggedIn && (
          <>
            <Link legacyBehavior href="/user/login#referrer">
              <a className="py-2">Login</a>
            </Link>
            <Link legacyBehavior href="/user/register">
              <a className="py-2">Registrieren</a>
            </Link>
          </>
        )}
        <Link href={result ? `${appUrl()}/topics/${result.url}` : '#sleepcheck'} className="py-2">
          Mein Sleep-Check
        </Link>
        {isLoggedIn && (
          <Link href={`/coachings/${user.id}`} className="py-2">
            Mein Sleep-Coaching
          </Link>
        )}
      </div>
    </div>
  )
}

export default AccountButton
