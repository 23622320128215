import {
  IProductVariant,
  IProductVariantsBySKU,
  ICartItem,
  IProduct
} from '@modules/products/types'

export const sortVariants = (variantsBySKU: IProductVariantsBySKU): IProductVariant[] => {
  const variants = Object.values(variantsBySKU)

  return variants.sort((variantA, variantB) => {
    const variantAPriceToFloat = parseFloat(variantA.price)
    const variantBPriceToFloat = parseFloat(variantB.price)

    return variantAPriceToFloat - variantBPriceToFloat || variantA.name.localeCompare(variantB.name)
  })
}

export const mapProductToCartItem = (product: IProduct): ICartItem => ({
  shopifyProductId: product.shopifyProductId,
  brand: product.brand.name,
  tags: product.tags,
  variants: product.variants.map((productVariant) => ({
    shopifyVariantId: productVariant.shopifyVariantId,
    stockStatus: productVariant.stockStatus,
    availabilityDate: productVariant.availabilityDate,
    deliveryTime: productVariant.deliveryTime
  }))
})
