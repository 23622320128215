import Link from 'next/link'
import React, { useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import trackEvent from '@lib/tracking/trackEvent'
import UiContext from '@context/UiContext'
import SleepProgramContext from '@context/SleepProgramContext'

import { XMarkIcon } from '@heroicons/react/24/outline'
import SignUp from './SignUp'

type OpacityAnimation = {
  opacity: number
}

const SleepProgram = () => {
  const { showSleepProgram } = useContext(UiContext) || {}
  const { resetSleepProgram } = useContext(SleepProgramContext) || {}

  return (
    <AnimatePresence onExitComplete={resetSleepProgram}>
      {showSleepProgram && (
        <motion.div
          className="flex bg-transparent w-full p-1 dialog-background md:p-2 lg:p-4"
          initial={{ opacity: 0 } as OpacityAnimation}
          animate={{ opacity: 1 } as OpacityAnimation}
          onAnimationComplete={(definition) => {
            if ((definition as OpacityAnimation).opacity === 1) {
              trackEvent({ event: 'sleep_program_open' })
            }
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}>
          <div className="w-full flex flex-col h-full  max-h-[900px] shadow-md rounded bg-white overflow-hidden max-w-md m-auto relative">
            <Link href="#_" className="absolute right-0 top-0 z-1 p-2.5 text-black opacity-70">
              <XMarkIcon className="h-7 w-7 p-0.5 bg-white rounded-full" aria-hidden="true" />
            </Link>

            <SignUp />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SleepProgram
