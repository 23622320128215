import React, { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import CookieBanner from '@components/shared/CookieBanner'
import { SleepProgram } from '@components/SleepProgram'
import { SleepCheck } from '@components/SleepCheck'
import Alert from '@components/shared/Alert'
import Toast from '@components/Toast'
import { IMenuItem } from '@modules/menu/types'
import ScrollToTopButton from '@components/content/product/ScrollToTopButton'
import SlideOver from '@modules/checkout/components/ShoppingCart/SlideOver'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import MetaTags from './Head/MetaTags'

type LayoutProps = {
  description: string
  metaImage?: string
  metaItemPropDescription?: string
  metaOgType?: string
  metaProductLink?: string
  metaProductPrice?: string
  title: string
  indexable?: boolean
  preview?: boolean
  topMenu: IMenuItem[]
  children: React.ReactNode
}

const Layout = ({
  children,
  description,
  indexable = true,
  metaImage,
  metaItemPropDescription,
  metaOgType = 'website',
  metaProductLink,
  metaProductPrice,
  preview = false,
  title,
  topMenu
}: LayoutProps) => {
  useEffect(() => {
    fetch('/api/create-session')
  }, [])

  const canonicalURL = `https://yoself.de${useRouter().asPath}`

  return (
    <>
      <Head>
        {/* NOTE: HTML Meta Tags */}
        <title>{title}</title>
        <MetaTags
          title={title}
          description={description}
          indexable={indexable}
          metaImage={metaImage}
          metaItemPropDescription={metaItemPropDescription}
          metaOgType={metaOgType}
          metaProductLink={metaProductLink}
          metaProductPrice={metaProductPrice}
          canonicalURL={canonicalURL}
        />
        <link rel="canonical" href={canonicalURL} />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#000000" />
        <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <div className="flex flex-col font-main">
        {preview ? <Alert /> : null}
        <Header topMenu={topMenu} />
        <SlideOver />
        <Toast />
        <main className="flex-grow font-main">{children}</main>
        <CookieBanner />
        <SleepCheck />
        <SleepProgram />
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  )
}

export default Layout
