type ValueOf<T> = T[keyof T]

export interface IDatoCMSContentSection {
  id: string
  _modelApiKey: ValueOf<ModelApiKey>
}

export interface IStep {
  id: string
  title: string
  contentSections: IDatoCMSContentSection[]
}

export enum ModelApiKey {
  BENEFIT_LIST = 'benefit_list',
  BRAND_INTRO = 'brand_intro',
  BRANDS_TEASER = 'brands_teaser',
  BUNDLE_TEASER = 'bundle_teaser',
  CALL_TO_ACTION_SECTION = 'call_to_action_section',
  CATEGORY_POSTS_TEASER = 'category_posts_teaser',
  CATEGORY_TEASER = 'category_teaser',
  COMMUNITY_STORY_OVERVIEW = 'community_story_overview',
  COMPARISON_TABLE = 'comparison_table',
  DIVIDER = 'divider',
  EDITORIAL = 'editorial',
  EXPERT_TEASER = 'expert_teaser',
  EXPERT_RECOMMENDATION = 'expert_recommendation',
  FAQ_LIST = 'faq_list',
  FEATURED_IN = 'featured_in',
  FOUNDER_STORY = 'founder_story',
  GPT_ASSESSMENT = 'gpt_assessment',
  HERO_PRODUCT = 'hero_product',
  ICONS_WITH_TEXT = 'icons_with_text',
  IMAGE_GALLERY = 'image_gallery',
  INSTAGRAM_STORIES_GALLERY = 'instagram_stories_gallery',
  JOURNEY = 'sleep_program_journey',
  MASTERCLASSES_CATEGORY_TEASER = 'masterclasses_category_teaser',
  MASTERCLASSES_TEASER = 'masterclasses_teaser',
  MOOD_IMAGE = 'mood_image',
  NEWSLETTER_TEASER = 'newsletter_teaser',
  ONE_IMAGE = 'one_image',
  PERSONALIZABLE_POSTS_TEASER = 'personalizable_posts_teaser',
  PERSONALIZABLE_TIP_BLOCK = 'personalizable_tip_block',
  PERSONALIZABLE_TEXT_PRODUCT_AND_POST_ELEMENT = 'personalizable_text_product_and_post_element',
  PERSONALIZABLE_HERO_PRODUCT_LIST = 'personalizable_hero_product_list',
  PODCAST_TEASER = 'podcast_teaser',
  POSTS_TEASER = 'posts_teaser',
  PRICING_TABLE = 'pricing_table',
  PRICING_COMPARISON = 'pricing_comparison',
  PRODUCT_LIST = 'product_list',
  SLEEP_SCORE = 'sleep_score',
  SOCIAL_MEDIA_BLOCK = 'social_media_block',
  STEP_DIVIDER = 'step_divider',
  TESTIMONIAL_OVERVIEW = 'testimonial_overview',
  TEXT_CONTENT_SECTION = 'text_content_section',
  THEMATIC_RECOMMENDATION = 'thematic_recommendation',
  TOP_X_LIST = 'top_x_list',
  TOPIC_INTRO = 'topic_intro',
  TOPIC_OVERVIEW = 'topic_overview'
}

export enum InternalLinkBasePath {
  product = '/products',
  brand = '/brands',
  category = '/categories',
  expert = '/experts',
  post = '/posts',
  topic = '/topics',
  static_page = '',
  masterclass = '/masterclasses'
}
