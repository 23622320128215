import { IShopifyCheckout } from '@lib/shopify/interfaces'
import Client from 'shopify-buy/index.unoptimized.umd'

const shopifyClient: ShopifyBrowserClient = Client.buildClient({
  domain: process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_BUY_API as string,
  storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN as string
})

type QueryResponse = {
  data: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type QueryDocument = {
  defineFragment: (...args: unknown[]) => string
  addQuery: (...args: unknown[]) => unknown
}

export type ShopifyBrowserClient = {
  checkout: {
    fetch: (id: string) => Promise<IShopifyCheckout>
    create: () => Promise<IShopifyCheckout>
    addDiscount: (checkoutId: string, discountCode: string) => Promise<IShopifyCheckout>
    addLineItems: (
      checkoutId: string,
      lineItems: { variantId: string; quantity: number }[]
    ) => Promise<IShopifyCheckout>
    updateLineItems: (
      checkoutId: string,
      lineItems: { id: string; quantity: number }[]
    ) => Promise<IShopifyCheckout>
    removeLineItems: (checkoutId: string, lineItemsId: string[]) => Promise<IShopifyCheckout>
  }
  graphQLClient: {
    variable: (name: string, type: string) => unknown
    document: () => QueryDocument
    send: (
      queryFn: () => QueryDocument,
      variables: Record<string, unknown>
    ) => Promise<QueryResponse>
  }
}
export default shopifyClient
