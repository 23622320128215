import React from 'react'

import Link from 'next/link'
import TaxAndShippingText from '@components/TaxAndShippingText'
import Image from 'next/image'

const Footer: React.FC = () => (
  <footer className="border-t border-gray-300">
    <div className="bg-white py-8 text-black section-content-width">
      <div className="flex flex-wrap w-full justify-start lg:justify-between mx-auto">
        <div className="flex flex-wrap flex-col md:flex-row text-left lg:text-left pb-4 lg:pr-16 w-full lg:w-auto justify-start  lg:justify-between ">
          <div>
            Du hast noch Fragen?
            <br />
            Wir sind gern für dich da unter <br />
            <span className="font-semibold mb-2 inline">
              <a className="inline" href="tel:+4915111680353">
                +49 (0) 151 11680 353
              </a>
              <span className="text-xs font-thin"> (kostenlos)</span>
            </span>
            <br />
            <p className="inline"> und </p>
            <span className="font-semibold mb-2 inline">
              <a className="inline" href="mailto:hello@yoself.de">
                hello@yoself.de
              </a>
            </span>
            <div id="instagramLink" className="flex items-center justify-left mt-4">
              <Link legacyBehavior href="https://www.instagram.com/yoself_official/">
                <a target="_blank" className="flex items-center">
                  <Image
                    src="/images/icons/icon_socialmedia_instagram_black.svg"
                    width="32"
                    height="32"
                    alt="instagram"
                  />
                  <span className="ml-2">@yoself_official</span>
                </a>
              </Link>
            </div>
          </div>
          {/* <div className="flex mt-4 md:mt-0 md:mr-2 lg:ml-10 md:ml-0"></div> */}
        </div>

        <div className="flex flex-wrap justify-start lg:justify-end w-full lg:w-auto mt-10 lg:mt-0">
          <ul className="gap-4 md:gap-8 space-y-4 md:space-y-0 md:grid md:grid-flow-col md:grid-rows-1 w-auto text-left lg:text-right">
            <li>
              <Link legacyBehavior href="/agb">
                <a className="hover:underline">AGB</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/datenschutz">
                <a className="hover:underline">Datenschutz</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/widerruf">
                <a className="hover:underline">Widerrufsbelehrung</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/impressum">
                <a className="hover:underline">Impressum</a>
              </Link>
            </li>
            <li>
              <Link legacyBehavior href="/ueber-uns">
                <a className="hover:underline">Über uns</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-wrap lg:justify-between w-full text-left  lg:text-right mt-12 md:mt-10">
          <p className="w-full lg:w-1/2 text-left lg:text-left pb-4">
            {new Date().getFullYear()} – yoself.de
          </p>
          <p className="text-gray-400 text-xs w-full lg:w-1/2 text-left lg:text-right">
            Preisangaben <TaxAndShippingText />
            <br />
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
