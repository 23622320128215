import React, { useContext, useState } from 'react'
import { motion } from 'framer-motion'

import SleepCheckContext from '@context/SleepCheckContext'
import { SleepCheckAnswers, SleepCheckApiTransmission, Step } from '@modules/sleepcheck/types'
import AuthClient from '@modules/auth/AuthClient'
import trackEvent from '@lib/tracking/trackEvent'
import SpinnerIcon from '@components/icons/Spinner'
import Link from 'next/link'
import { useRouter } from 'next/router'

const authClient = new AuthClient()

interface IResultScreen {
  steps: { [key: string]: Step }
  setFakeCalculation: (value: boolean) => void
}

const ResultScreen: React.FC<IResultScreen> = ({ steps, setFakeCalculation }) => {
  const { answersMap, setAnswersMap, setResult } = useContext(SleepCheckContext)
  const [email, setEmail] = useState(null)
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const handleSubmit = async (event, withoutMail = false) => {
    if (event) event.preventDefault()
    setFakeCalculation(true)
    const allStepKeys = new Map(Object.keys(steps).map((object) => [object, null]))
    const sleepCheckResult: SleepCheckAnswers = Object.fromEntries(
      new Map([...allStepKeys, ...answersMap])
    ) as SleepCheckAnswers
    const apiTransmission: SleepCheckApiTransmission = {
      email: withoutMail ? null : email,
      sleepCheck: sleepCheckResult
    }

    const result = await authClient.saveSleepCheck(apiTransmission)

    trackEvent({
      event: 'sleep_check_completed',
      sleepType: result.type,
      sleepCheckID: result.id,
      sleepCheckResult: result.data,
      email: result.email,
      personalizedUrl: `https://yoself.de/topics/${result.url}?sleep_check_id=${result.id}`
    })

    if (result.url) {
      setAnswersMap(new Map())
      setResult(result)
      await router.push(`/topics/${result.url}`)
    } else {
      setLoading(false)
      throw new Error('No URL returned')
    }
  }

  return (
    <>
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center">
          <SpinnerIcon size={80} />
        </div>
      )}
      <motion.div
        className="p-6 overflow-y-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}>
        <h3 className="font-bold text-lg mb-4">Vielen Dank für deine Teilnahme</h3>
        <p className="my-2">
          Gleich erhälst du <b>dein Ergebnis</b>
        </p>
        <p className="mb-2">Du kannst dich auch für unseren Newsletter anmelden.</p>
        <h3 className="font-bold mb-2">Deine Vorteile bei Anmeldung:</h3>
        <ul className="mb-4 mt-0.5">
          <li className="before:content-['•'] before:ml-0.5 before:mr-2">
            📚 gratis E-Book im Wert von 14,95€
          </li>
          <li className="before:content-['•'] before:ml-0.5 before:mr-2">
            💤 Regelmäßige Tipps für besseren Schlaf
          </li>

          <li className="before:content-['•'] before:ml-0.5 before:mr-2">🏷️ Exklusive Angebote</li>
        </ul>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            className="w-full rounded border-2 border-gray-200"
            autoComplete="email"
            placeholder="Deine E-Mail-Adresse"
            onChange={(event) => setEmail(event.target.value)}
            required
          />
          <div className="flex items-center my-4 text-xs">
            <label htmlFor="privacy">
              Mit meiner Anmeldung zum Newsletter akzeptiere ich die{' '}
              <Link legacyBehavior href="/datenschutz">
                <a className="underline">Datenschutzbestimmungen</a>
              </Link>
              . Abmeldung jederzeit möglich.
            </label>
          </div>
          <button
            type="submit"
            className="bg-black text-white mt-3 mb-2 flex items-center justify-center border-2 border-gray-200 w-full h-12 rounded disabled:bg-slate-100 disabled:border-white">
            <span className="font-bold uppercase">Anmelden und anzeigen</span>
          </button>
          <button
            type="button"
            onClick={() => handleSubmit(null, true)}
            className="flex items-center justify-center border-0 bg-transparent rounded-none text-gray-700 w-full h-12 disabled:text-gray-200">
            <span className="underline">Ohne Anmeldung anzeigen</span>
          </button>
        </form>
      </motion.div>
    </>
  )
}

export default ResultScreen
