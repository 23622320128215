import React from 'react'
import { shippingCost, shippingThreshold } from '@utils/constants'

interface TaxAndShippingTextProps {
  totalPrice?: number
  qualifiesForFreeShipping?: boolean
}

const TaxAndShippingText = ({ totalPrice, qualifiesForFreeShipping }: TaxAndShippingTextProps) => {
  const isFreeShipping = qualifiesForFreeShipping || totalPrice >= shippingThreshold
  const shippingText = qualifiesForFreeShipping
    ? 'versandkostenfrei'
    : `zzgl. ${shippingCost} Versand`

  return (
    <span className="text-xs text-gray-400 mr-2">
      inkl. MwSt. {isFreeShipping ? 'versandkostenfrei' : shippingText}
    </span>
  )
}

export default TaxAndShippingText
