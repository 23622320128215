import React, { createContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { IProduct } from '@modules/products/types'

type UiContextProps = {
  children?: JSX.Element
}
export interface ICrossSellingDialog {
  product: IProduct
  variantSKU: string
  quantity: number
  isOpen: boolean
}

/* eslint-disable */
const defaultValues = {
  crossSellingDialog: {
    product: null,
    variantSKU: null,
    quantity: 0,
    isOpen: false
  },
  setCrossSellingDialog: (crossSellingDialog: ICrossSellingDialog) => {},
  showShoppingCart: false,
  setShowShoppingCart: (showShoppingCart: boolean) => {},
  setProductListsCurrentlyExpanded: (id) => {},
  productListsExpanded: [],
  openedMenuItemId: 0,
  setOpenedMenuItemId: (id) => {},
  showSleepCheck: false,
  setShowSleepCheck: (showSleepCheck: boolean) => {},
  showSleepProgram: false,
  setShowSleepProgram: (showSleepProgram: boolean) => {},
  mobileMenuOpen: false,
  setMobileMenuOpen: (mobileMenuOpen: boolean) => {},
  desktopMenuOpen: false,
  setDesktopMenuOpen: (desktopMenuOpen: boolean) => {},
  closeMobileMenu: () => {},
  activeMenuSlide: null,
  setActiveMenuSlide: (activeMenuSlide: string) => {},
  carouselPositions: {},
  setCarouselPositions: (carouselPositions: {}) => {},
  stepsState: {},
  setStepsState: (setStepsState: {}) => {},
  coachingView: 'coaching-program',
  setCoachingView: (coachingView: string) => {},
  scrollLevel: 0,
  setScrollLevel: (scrollLevel: number) => {}
}
/* eslint-enable */

const UiContext = createContext(defaultValues)

export const UiContextProvider = ({ children }: UiContextProps) => {
  const [showShoppingCart, setShowShoppingCart] = useState(false)
  const [showSleepCheck, setShowSleepCheck] = useState(false)
  const [showSleepProgram, setShowSleepProgram] = useState(false)
  const [productListsExpanded, setProductListsExpanded] = useState([])
  const [carouselPositions, setCarouselPositions] = useState({})
  const [stepsState, setStepsState] = useState({})
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [desktopMenuOpen, setDesktopMenuOpen] = useState(false)
  const [activeMenuSlide, setActiveMenuSlide] = useState(null)
  const [crossSellingDialog, setCrossSellingDialog] = useState<ICrossSellingDialog>({
    product: null,
    variantSKU: null,
    quantity: 0,
    isOpen: false
  })
  const [openedMenuItemId, setOpenedMenuItemId] = useState(0)
  const [coachingView, setCoachingView] = useState('coaching-program')
  const [scrollLevel, setScrollLevel] = useState(0)
  const setProductListsCurrentlyExpanded = (id) =>
    setProductListsExpanded([...productListsExpanded, id])
  const fixBody = (fix: boolean) => {
    if (fix) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }
  const router = useRouter()

  useEffect(() => {
    const path = router.asPath
    if (path.includes('#sleepcheck')) {
      setShowSleepCheck(true)
      fixBody(true)
    } else if (path.includes('#cart')) {
      setShowShoppingCart(true)
      fixBody(true)
    } else if (path.includes('#sleep-program')) {
      setShowSleepProgram(true)
      fixBody(true)
    } else {
      setShowShoppingCart(false)
      setShowSleepCheck(false)
      setShowSleepProgram(false)
      fixBody(false)
    }
  }, [router.asPath])

  const closeMenu = () => {
    setDesktopMenuOpen(false)
    fixBody(false)
    setMobileMenuOpen(false)
  }

  return (
    <UiContext.Provider
      value={{
        crossSellingDialog,
        setCrossSellingDialog,
        showShoppingCart,
        setShowShoppingCart,
        setProductListsCurrentlyExpanded,
        productListsExpanded,
        openedMenuItemId,
        setOpenedMenuItemId,
        showSleepCheck,
        setShowSleepCheck,
        showSleepProgram,
        setShowSleepProgram,
        mobileMenuOpen,
        setMobileMenuOpen,
        desktopMenuOpen,
        setDesktopMenuOpen,
        closeMobileMenu: closeMenu,
        activeMenuSlide,
        setActiveMenuSlide,
        carouselPositions,
        setCarouselPositions,
        stepsState,
        setStepsState,
        coachingView,
        setCoachingView,
        scrollLevel,
        setScrollLevel
      }}>
      {children}
    </UiContext.Provider>
  )
}

export default UiContext

export enum StepState {
  ACTIVE = 'active',
  DONE = 'done'
}
