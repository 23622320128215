import React, { createContext, useState } from 'react'

type SearchContextProps = {
  children?: JSX.Element
}

/* eslint-disable */
const defaultValues = {
  isOpen: false,
  setIsOpen: (status: boolean): void => {},
  query: '',
  setQuery: (query: string): void => {},
  pagination: { products: 3, brands: 4, posts: 3, categories: 4 },
  setPage: (pagination: {
    products: number
    brands: number
    posts: number
    categories: number
  }): void => {},
  result: { products: [], brands: [], posts: [], categories: [] },
  setResult: ({ products: [], brands: [], posts: [], categories: [] }): void => {}
}
/* eslint-enable */

const SearchContext = createContext(defaultValues)

export const SearchContextProvider = ({ children }: SearchContextProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(defaultValues.isOpen)
  const [searchPagination, setSearchPagination] = useState(defaultValues.pagination)
  const [searchQuery, setSearchQuery] = useState(defaultValues.query)
  const [searchResult, setSearchResult] = useState(defaultValues.result)

  return (
    <SearchContext.Provider
      value={{
        isOpen: isSearchOpen,
        setIsOpen: setIsSearchOpen,
        query: searchQuery,
        setQuery: setSearchQuery,
        pagination: searchPagination,
        setPage: setSearchPagination,
        result: searchResult,
        setResult: setSearchResult
      }}>
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContext
