import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import ArrowToRightIcon from '@components/icons/ArrowToRightIcon'
import SearchContext from '@context/SearchContext'
import { IPostSearchItem } from '@lib/SearchIndexService/types'

interface PostSearchResultsProps {
  pagination: number
  posts: IPostSearchItem[]
}

const PostSearchResults = ({ pagination, posts }: PostSearchResultsProps) => {
  const search = React.useContext(SearchContext)

  return (
    <div className="pb-9">
      <ul className="list-none w-full">
        {posts.slice(0, pagination).map((post) => (
          <li key={post.objectID} className="my-6">
            <Link legacyBehavior href={post.link}>
              <a href={post.link} onClick={() => search.setIsOpen(false)}>
                <div className="w-full flex">
                  <div className="w-1/3 shrink-0 group">
                    {post.image.responsiveImage ? (
                      <Image
                        alt={post.title}
                        className="md:group-hover:scale-105 transition-all object-cover"
                        src={post.image.responsiveImage.src}
                        sizes="(min-width:768px) 25vw, 50vw"
                        width={post.image.responsiveImage.width}
                        height={post.image.responsiveImage.height}
                      />
                    ) : (
                      <div className="relative aspect-w-4 aspect-h-3">
                        <Image
                          alt={post.title}
                          className="md:group-hover:scale-105 transition-all object-cover"
                          src={post.image.src}
                          fill
                          sizes="(min-width:768px) 25vw, 50vw"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col justify-center pl-5">
                    <p className="uppercase text-xs md:text-sm mb-1">{post.category}</p>
                    <h2 className="font-bold text-xs lg:text-base xl:text-base mt-1 hover:underline">
                      {post.title}
                    </h2>
                    <div className="flex items-center py-2 1/5 ">
                      <ArrowToRightIcon />
                      <span className="uppercase ml-3 text-xs md:text-sm font-bold">
                        Zum Artikel
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          </li>
        ))}
      </ul>

      {/* {showMoreProducts ? } */}
    </div>
  )
}

export default PostSearchResults
