import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import React from 'react'

type INoResultsPlaceholder = {
  resultType: string
}

const NoResultsPlaceholder: React.FC<INoResultsPlaceholder> = ({ resultType }) => (
  <div className="opacity-50 flex items-center ">
    <DocumentMagnifyingGlassIcon className="h-6 mr-4" />

    <h3 className="test-base font-bold">
      Keine <span className="capitalize">{resultType}</span> gefunden.
    </h3>
  </div>
)

export default NoResultsPlaceholder
