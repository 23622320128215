export const getAnswersMapType11 = (): Map<string, string | number | boolean | []> => {
  const answersMapType = new Map()

  answersMapType.set('quality', 69)
  answersMapType.set('wellRested', true)
  answersMapType.set('easyStart', false)
  answersMapType.set('hours', '7-8')
  answersMapType.set('stressed', false)
  answersMapType.set('windDown', false)
  answersMapType.set('restless', false)
  answersMapType.set('worries', false)
  answersMapType.set('comeToRest', false)
  answersMapType.set('notTired', false)
  answersMapType.set('fear', false)
  answersMapType.set('thoughts', false)
  answersMapType.set('wakeUp', false)
  answersMapType.set('childrenPets', false)
  answersMapType.set('toilet', false)
  answersMapType.set('hungerThirst', false)
  answersMapType.set('externalInfluences', false)
  answersMapType.set('wakeUpEarlier', false)
  answersMapType.set('threeWeeks', true)
  answersMapType.set('devices', false)
  answersMapType.set('sameTime', false)
  answersMapType.set('caffeine', false)
  answersMapType.set('sweat', false)
  answersMapType.set('freeze', false)
  answersMapType.set('workInBedroom', false)
  answersMapType.set('physicallyActive', false)
  answersMapType.set('meals', false)
  answersMapType.set('relaxation', false)
  answersMapType.set('routine', false)
  answersMapType.set('healthyFood', false)
  answersMapType.set('comfortable', false)
  answersMapType.set('alcohol', false)
  answersMapType.set('measures', ['Meditation'])
  answersMapType.set('children', false)
  answersMapType.set('mate', false)
  answersMapType.set('shiftWork', false)
  answersMapType.set('age', '25-34 Jahre')
  answersMapType.set('gender', 'divers')
  answersMapType.set('expectations', ['test'])
  return answersMapType
}
