import React, { useContext, useEffect } from 'react'
import { Step } from '@modules/sleepcheck/types'
import classNames from 'classnames'
import SleepCheckContext from '@context/SleepCheckContext'

interface IProgressBar {
  steps: { [key: string]: Step }
  currentStep: Step
}

const ProgressBar: React.FC<IProgressBar> = ({ steps, currentStep }) => {
  const { answersMap } = useContext(SleepCheckContext)
  const [percentage, setPercentage] = React.useState(0)
  const [delayedPercentage, setDelayedPercentage] = React.useState(0)

  useEffect(() => {
    if (answersMap.size) {
      const answeredQuestionsLength: number = answersMap.size
      const stepsKeys: string[] = Object.keys(steps)
      const currentStepIndex: number = stepsKeys.indexOf(currentStep.slug)
      const remainingQuestionsLength: number = stepsKeys.slice(currentStepIndex).length
      const displayedPercentage =
        (answeredQuestionsLength / (answeredQuestionsLength - 1 + remainingQuestionsLength)) * 100
      const fakePercentage = Math.round(
        displayedPercentage + (3 * (100 - displayedPercentage)) / 100
      )
      setPercentage(fakePercentage)
      setTimeout(() => {
        setDelayedPercentage(fakePercentage)
      }, 900)
    }
  }, [currentStep])

  return (
    <div className="px-6 pt-6 pb-0.5 bg-white">
      <div
        className={classNames(
          'relative text-sm inline-block transition-all duration-500 delay-700',
          { '-translate-x-1/2': percentage >= 1 },
          { 'translate-x-0': percentage < 1 }
        )}
        style={{ left: `${percentage}%` }}>
        {delayedPercentage}%
      </div>
      <div
        className="h-1 rounded-full bg-gray-200 bg-gradient-to-r from-black to-black bg-no-repeat duration-500 delay-700"
        style={{ backgroundSize: `${percentage}% 100%` }}
      />
    </div>
  )
}

export default ProgressBar
