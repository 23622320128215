import React from 'react'
import classNames from 'classnames'
import { AnswerOption, Step } from '@modules/sleepcheck/types'

interface IRadioGroup {
  step: Step
  setCurrentValue: (value: string) => void
}

const RadioGroup: React.FC<IRadioGroup> = ({ step, setCurrentValue }) => {
  const [checked, setChecked] = React.useState(null)
  const getId = (option: AnswerOption) => `${step.slug}-${option.label}`

  const handleChange = (e) => {
    const { id, value } = e.target
    setChecked(id)
    setCurrentValue(value)
  }

  return (
    <div className="my-6 flex flex-col border-collapse w-full">
      {step.answer.options.map((option) => {
        const id = getId(option)

        return (
          <div
            key={option.label}
            className={classNames('border-2 -my-[1px] h-12 first:rounded-t last:rounded-b', {
              'border-black bg-gray-200 relative z-1': checked === id,
              'border-gray-300': checked !== id
            })}>
            <input
              type="radio"
              key={id}
              id={id}
              name={step.slug}
              value={option.label}
              className="appearance-none hidden"
              onChange={handleChange}
            />
            <label
              htmlFor={id}
              className="block w-full px-4 h-full flex items-center justify-between cursor-pointer">
              <span>{option.label}</span>
              <div
                className={classNames('h-5 w-5 rounded-full border-2 p-[3px]', {
                  'border-black after:content-[""] after:block after:rounded-full after:h-full after:w-full after:bg-black':
                    checked === id,
                  'border-grey-300': checked !== id
                })}
              />
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default RadioGroup
