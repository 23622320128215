import React, { useContext, useEffect, useRef } from 'react'
import Link from 'next/link'

import { AnimatePresence, motion } from 'framer-motion'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SleepCheckContext from '@context/SleepCheckContext'
import UiContext from '@context/UiContext'
import { Step } from '@modules/sleepcheck/types'
import { steps, ScreenIDs, START_SLUG } from '@modules/sleepcheck/steps'
import { getAnswersMapType11 } from '@modules/sleepcheck/filledSteps'
import SpinnerIcon from '@components/icons/Spinner'
import trackEvent from '@lib/tracking/trackEvent'
import ProgressBar from './ProgressBar'
import StepSlide from './StepSlide'
import ResultScreen from './ResultScreen'
import ImageFader from './ImageFader'
import TesterRegistration from './TesterRegistration'

const SleepCheck = () => {
  const ref = useRef<HTMLFormElement>(null)
  const { answersMap, setAnswersMap } = useContext(SleepCheckContext) || {}
  const { showSleepCheck } = useContext(UiContext)
  const [displayedSteps, setDisplayedSteps] = React.useState<Step[]>([steps[START_SLUG]])
  const [currentStep, setCurrentStep] = React.useState(steps[START_SLUG])
  const [screen, setScreen] = React.useState<ScreenIDs>(ScreenIDs.SLEEP_CHECK)
  const [fakeCalculation, setFakeCalculation] = React.useState(false)
  const [loadingPercentage, setLoadingPercentage] = React.useState(0)
  const [loadingTime, setLoadingTime] = React.useState(0)

  const addStep = (slug: string) => {
    if (slug === 'emailScreen') {
      setScreen(ScreenIDs.EMAIL_SCREEN)
    } else {
      const newSteps = []
      Array.from(answersMap).forEach(([key]) => {
        newSteps.push(steps[key])
      })
      newSteps.push(steps[slug])
      setDisplayedSteps(newSteps)
    }
  }

  const scrollToStep = (slug: string, delay = 0) => {
    setTimeout(() => {
      const stepElement = document.getElementById(slug)

      if (stepElement) {
        stepElement.scrollIntoView()
      }
    }, delay)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === '€') {
      setAnswersMap(getAnswersMapType11())
    }
  }

  useEffect(() => {
    if (fakeCalculation) {
      window.setInterval(() => {
        setLoadingTime((prev) => prev + 0.5)
      }, 500)
    }
  }, [fakeCalculation])

  useEffect(() => {
    if (loadingPercentage < 100) setLoadingPercentage(Math.floor((loadingTime / 30) * 100))
  }, [loadingTime])

  useEffect(() => {
    if (showSleepCheck) {
      window.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [showSleepCheck])

  useEffect(() => {
    const currentStepSlug = displayedSteps[displayedSteps.length - 1].slug
    setCurrentStep(steps[currentStepSlug])
    scrollToStep(currentStepSlug, 400)
  }, [displayedSteps, showSleepCheck])

  useEffect(() => {
    if (answersMap.size) {
      const currentStepSlug = [...answersMap][answersMap.size - 1][0]
      const nextStepSlug = steps[currentStepSlug].answer.getNextStepSlug(answersMap)
      addStep(nextStepSlug)
    } else {
      setDisplayedSteps([steps[START_SLUG]])
    }
  }, [answersMap])

  const onSubmit = ({ step, currentValue }) => {
    const map = new Map(answersMap.set(step.slug, currentValue))
    const arrayFromMap = Array.from(map)
    const index = arrayFromMap.findIndex(([key]) => key === step.slug)
    const newArray = arrayFromMap.slice(0, index + 1)
    setAnswersMap(new Map(newArray))
  }
  return (
    <AnimatePresence>
      {showSleepCheck && (
        <motion.div
          className="flex bg-transparent w-full p-1 dialog-background md:p-2 lg:p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onAnimationComplete={(definition) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((definition as any).opacity === 1) {
              trackEvent({ event: 'sleep_check_open' })
            }
          }}
          exit={{ opacity: 0.3 }}
          transition={{ duration: 0.3 }}>
          <div className="w-full flex flex-col h-full  max-h-[900px] shadow-md rounded bg-white overflow-hidden max-w-md m-auto relative">
            {!fakeCalculation && (
              <motion.div className="w-full h-full flex flex-col" exit={{ opacity: 0 }}>
                <div className="sticky left-0 top-0 relative">
                  <Link legacyBehavior href="#_">
                    <a className="absolute right-0 top-0 z-1 p-2.5 text-black opacity-70">
                      <XMarkIcon
                        className="h-7 w-7 p-0.5 bg-white rounded-full"
                        aria-hidden="true"
                      />
                    </a>
                  </Link>
                  <div>
                    <ImageFader steps={steps} currentStep={currentStep} screen={screen} />
                    <ProgressBar steps={steps} currentStep={currentStep} />
                  </div>
                </div>
                {screen === ScreenIDs.SLEEP_CHECK && (
                  <form
                    ref={ref}
                    id="sleepcheck-scrollarea"
                    className="flex flex-grow h-full overflow-hidden no-scrollbar scroll-smooth snap-x snap-mandatory">
                    {displayedSteps.map((step) => (
                      <StepSlide key={step.slug} step={step} onSubmit={onSubmit} />
                    ))}
                  </form>
                )}
                {screen === ScreenIDs.EMAIL_SCREEN && (
                  <ResultScreen steps={steps} setFakeCalculation={setFakeCalculation} />
                )}
                {screen === ScreenIDs.TESTER_REGISTRATION && (
                  <TesterRegistration setFakeCalculation={setFakeCalculation} />
                )}
              </motion.div>
            )}
            {fakeCalculation && (
              <motion.div
                className="h-full flex flex-col text-left items-center justify-center ml-4 mr-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}>
                <div className="relative flex justify-center items-center">
                  <SpinnerIcon size={80} />
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center -mt-[3px] -ml-[3px]">
                    {loadingPercentage}%
                  </div>
                </div>
                <p className="text-left mb-4 mt-4 text-center">
                  Wir berechnen und ermitteln nun...
                  {loadingPercentage >= 100 && (
                    <span className="font-bold">
                      <br />
                      Leider dauert es diesmal etwas länger
                    </span>
                  )}
                </p>
                <div className="text-left">
                  <ul className="list-disc">
                    <li>Deinen persönlichen Sleep-Score</li>
                    {loadingPercentage >= 25 && <li>Unsere Einschätzung zu deinem Schlaf-Typ</li>}
                    {loadingPercentage >= 50 && <li>Personalisierte Tipps</li>}
                    {loadingPercentage >= 75 && <li>Individuelle Produktempfehlungen</li>}
                  </ul>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SleepCheck
