import React from 'react'

const CouponTag: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.861" viewBox="0 0 15 13.861">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rechteck_365"
          data-name="Rechteck 365"
          width="15"
          height="13.861"
          transform="translate(0 0)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Gruppe_414" data-name="Gruppe 414" transform="translate(0 0)">
      <g id="Gruppe_413" data-name="Gruppe 413" clipPath="url(#clip-path)">
        <path
          id="Pfad_5063"
          data-name="Pfad 5063"
          d="M14.309,2.109,10.268.135A1.441,1.441,0,0,0,8.924.2L3.114,3.555l5.032,8.718,5.811-3.355a1.441,1.441,0,0,0,.728-1.132L15,3.3a1.22,1.22,0,0,0-.687-1.191M12.527,5.118a1.1,1.1,0,1,1,.4-1.5,1.1,1.1,0,0,1-.4,1.5"
        />
        <path
          id="Pfad_5064"
          data-name="Pfad 5064"
          d="M2.186,4.091.722,4.936A1.363,1.363,0,0,0,.156,6.77l3.73,6.459a1.36,1.36,0,0,0,1.869.424l1.464-.845Z"
        />
      </g>
    </g>
  </svg>
)

export default CouponTag
