import React, { useContext } from 'react'

import SearchContext from '@context/SearchContext'
import UiContext from '@context/UiContext'
import classNames from 'classnames'

const BurgerMenu: React.FC = () => {
  const search = useContext(SearchContext)
  const { mobileMenuOpen, setMobileMenuOpen } = useContext(UiContext)

  const toggleMobileMenu = () => {
    const { isOpen, setIsOpen } = search
    if (isOpen) setIsOpen(!isOpen)
    if (mobileMenuOpen) {
      document.body.style.overflow = 'auto'
    } else {
      document.body.style.overflow = 'hidden'
    }
    setMobileMenuOpen(!mobileMenuOpen)
  }

  return (
    <button
      aria-label={mobileMenuOpen ? 'Close menu' : 'Open menu'}
      className={classNames('burger md:hidden mr-6 mt-0.5', { 'is-open': mobileMenuOpen })}
      type="button"
      onClick={toggleMobileMenu}>
      <span />
    </button>
  )
}

export default BurgerMenu
