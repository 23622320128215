import React from 'react'

type MetaTagsProps = {
  title: string
  description: string
  indexable: boolean
  metaImage: string
  metaItemPropDescription: string
  metaOgType: string
  metaProductLink: string
  metaProductPrice: string
  canonicalURL: string
}

const MetaTags = ({
  title,
  description,
  indexable,
  metaImage,
  metaItemPropDescription,
  metaOgType,
  metaProductLink,
  metaProductPrice,
  canonicalURL
}: MetaTagsProps) => (
  <>
    <meta name="description" content={description} key="description" />
    <meta
      name="robots"
      content={
        indexable === true && process.env.NEXT_PUBLIC_INDEX !== 'false'
          ? 'index,follow'
          : 'noindex,follow'
      }
      key="robots"
    />
    <meta name="revisit-after" content="3 days" key="revisit_after" />

    {/* NOTE: Google / Search Engine Tags */}
    <meta itemProp="name" content={title} key="prop_name" />
    <meta
      itemProp="description"
      content={metaItemPropDescription || description}
      key="prop_description"
    />
    {metaImage && <meta itemProp="image" content={metaImage} key="meta_image" />}
    <meta itemProp="isFamilyFriendly" content="True" key="is_family_friendly" />

    {/* NOTE: Facebook Meta Tags */}
    <meta property="og:site_name" content="yoself.de" key="og_site_name" />
    <meta property="og:url" content={canonicalURL} key="og_url" />
    <meta property="og:type" content={metaOgType} key="og_type" />
    <meta property="og:title" content={title} key="og_title" />
    <meta property="og:description" content={description} key="og_description" />
    {metaImage && <meta property="og:image" content={metaImage} key="og_image" />}

    {/* NOTE: Product Meta Tags */}
    <meta property="product:brand" content="The Greenest Greens" key="product_brand" />
    {metaProductPrice && (
      <>
        <meta
          property="product:price:amount"
          content={metaProductPrice}
          key="product_price_amount"
        />
        <meta property="product:price:currency" content="EUR" key="product_price_currency" />
      </>
    )}
    {metaProductLink && (
      <meta property="product:product_link" content={metaProductLink} key="product_link" />
    )}

    {/* NOTE: Twitter Meta Tags */}
    <meta name="twitter:card" content="" key="twitter_card" />
    <meta name="twitter:site" content="yoself.de" key="twitter_site" />
    <meta name="twitter:title" content={title} key="twitter_title" />
    <meta name="twitter:description" content={description} key="twitter_description" />
    {metaImage && <meta name="twitter:image" content={metaImage} key="twitter_image" />}
  </>
)

export default MetaTags
