import React, { useContext } from 'react'

import SearchContext from '@context/SearchContext'
import classnames from 'classnames'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

interface ISearchButton {
  buttonRef: React.RefObject<HTMLButtonElement>
}

const SearchButton: React.FC<ISearchButton> = ({ buttonRef }) => {
  const search = useContext(SearchContext)
  const { isOpen, setIsOpen } = search

  const toggleSearch = () => {
    setIsOpen(!isOpen)
  }

  return (
    <button
      type="button"
      id="searchIcon"
      aria-label="Suche"
      className={classnames('px-1 py-2 md:pb-2 md:pt-3 border-b-2', {
        'border-black': isOpen,
        'border-white': !isOpen
      })}
      ref={buttonRef}
      onClick={toggleSearch}>
      <MagnifyingGlassIcon className="w-6 h-6" />
    </button>
  )
}

export default SearchButton
