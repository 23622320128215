import React, { useContext, useEffect, useState } from 'react'

import { answerType, Step } from '@modules/sleepcheck/types'
import { SECOND_SLUG, START_SLUG } from '@modules/sleepcheck/steps'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import SleepCheckContext from '@context/SleepCheckContext'
import trackEvent from '@lib/tracking/trackEvent'
import YesNo from './YesNo'
import Range from '../Range'
import RadioGroup from './RadioGroup'
import CheckboxGroup from './CheckboxGroup/CheckboxGroup'
import Chips from './Chips'

type StepSlideProps = {
  step: Step
  onSubmit: ({ step, currentValue }) => void
}

const StepSlide = ({ step, onSubmit }: StepSlideProps) => {
  const { answersMap, setAnswersMap } = useContext(SleepCheckContext)
  const [currentValue, setCurrentValue] = useState(null)
  useEffect(() => {
    if (step.slug === SECOND_SLUG) trackEvent({ event: 'sleep_check_started' })
  }, [])

  const backToPreviousStep = () => {
    const prevStepSlug = [...answersMap][answersMap.size - 1][0]
    document.getElementById(prevStepSlug).scrollIntoView()
    setTimeout(() => {
      setAnswersMap(new Map([...answersMap].slice(0, -1)))
    }, 500)
  }

  return (
    <div
      id={step.slug}
      className="flex flex-col justify-between w-full flex-shrink-0 overflow-y-auto snap-always snap-start">
      <div className="p-6 flex flex-col flex-grow justify-between">
        <div>
          <h4 className="text-sm uppercase mb-2">{step.category}</h4>
          <h3 className="font-bold text-lg">{step.question}</h3>
          {step.answer && step.answer.type === answerType.YES_NO && (
            <YesNo step={step} setCurrentValue={setCurrentValue} onSubmit={onSubmit} />
          )}
          {step.answer && step.answer.type === answerType.RANGE && (
            <Range step={step} setCurrentValue={setCurrentValue} />
          )}
          {step.answer && step.answer.type === answerType.RADIO && (
            <RadioGroup step={step} setCurrentValue={setCurrentValue} />
          )}
          {step.answer && step.answer.type === answerType.CHECKBOX && (
            <CheckboxGroup step={step} setCurrentValue={setCurrentValue} />
          )}
          {step.answer && step.answer.type === answerType.CHIPS && (
            <Chips step={step} setCurrentValue={setCurrentValue} />
          )}
        </div>
        <div className="-mb-6 pb-6 flex sticky left-0">
          <button
            onClick={() => backToPreviousStep()}
            type="button"
            disabled={step.slug === START_SLUG}
            className="flex items-center justify-center rounded border-2 border-gray-200 h-12 w-12 shrink-0 disabled:text-gray-200">
            <ArrowLeftIcon className="h-12 p-2" />
          </button>
          <button
            type="button"
            disabled={currentValue === null || currentValue.length === 0}
            onClick={() => onSubmit({ step, currentValue })}
            className="flex items-center justify-center rounded border-2 border-gray-200 w-full h-12 ml-4 disabled:text-gray-200">
            <ArrowRightIcon className="h-12 p-2" />
            <span className="ml-4 font-bold uppercase">Weiter</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepSlide
