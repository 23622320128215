import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'

import SearchInput from '@components/shared/SearchInput'
import SearchResultsLayout from '@components/search/SearchResultsLayout'
import { ISearchPagination, ISearchResult } from '@modules/search/interfaces'
import useClickOutsideHandler from '@utils/clickOutsideHandler'
import ProductSearchResults from './ProductSearchResults'
import BrandSearchResults from './BrandSearchResults'
import PostSearchResults from './PostSearchResults'
import CategoriesSearchResults from './CategoriesSearchResults'

interface ISearchModal {
  setSearchVisibility: (boolean) => void
  result: ISearchResult
  searchQuery: string
  handleInputChange: (event) => void
  handleClear: () => void
  pagination: ISearchPagination
  setPage: (pagination: {
    products: number
    brands: number
    posts: number
    categories: number
  }) => void
  iconReference: React.RefObject<HTMLElement>
}

const SearchModal: React.FC<ISearchModal> = ({
  result,
  setSearchVisibility,
  searchQuery,
  handleInputChange,
  handleClear,
  pagination,
  setPage,
  iconReference
}) => {
  React.useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])
  const reference = React.useRef(null)
  useClickOutsideHandler([reference, iconReference], () => setSearchVisibility(false))

  const noResults =
    result.brands.length === 0 && result.products.length === 0 && result.posts.length === 0
  return (
    <div className="relative z-20" ref={reference}>
      <div
        className="w-screen h-screen absolute backdrop-blur bg-black bg-opacity-30 overflow-hidden"
        onKeyDown={(e) => {
          if (e.key === 'Escape') setSearchVisibility(false)
        }}
        onClick={() => {
          setSearchVisibility(false)
        }}
        role="presentation">
        <div
          id="search-animation"
          className={classnames('bg-white bottom-[20vh]', {
            'animate-searchSlide': true
          })}>
          <div
            className="section-content-width max-h-[95vh] md:max-h-[calc(100vh-250px)] flex flex-col"
            onClick={(e) => {
              e.stopPropagation()
            }}>
            <div className="-mx-4 px-4 py-6 border-b border-gray-200">
              <div className="h-10 md:h-12 flex justify-between items-center">
                <SearchInput
                  searchQuery={searchQuery}
                  handleInputChange={handleInputChange}
                  handleClear={handleClear}
                />
                <button className="h-3/5 " type="button" onClick={() => setSearchVisibility(false)}>
                  <XMarkIcon className="h-3/5 ml-4 lg:mr-0 stroke-1" />
                </button>
              </div>
            </div>
            {searchQuery && noResults ? (
              <div className="w-full md:mt-2">
                <h1 className="font-bold text-xl mb-6 md:mb-10 mt-6 mr-4">
                  Leider keine Ergebnisse für dich gefunden.
                </h1>
              </div>
            ) : (
              searchQuery && (
                <div className="md:mt-2 overflow-y-auto">
                  <h1 className="font-bold text-lg mb-5 md:mb-10 mt-5">
                    Das haben wir für dich gefunden:
                  </h1>

                  <div className="flex flex-col md:flex-row mb-10">
                    <div className="md:w-1/2 md:border-r-2 md:border-gray-300">
                      <SearchResultsLayout
                        resultType="produkte"
                        results={result.products}
                        currentPage={pagination.products}
                        setPage={(x: number) => setPage({ ...pagination, products: x })}>
                        <ProductSearchResults
                          products={result.products}
                          pagination={pagination.products}
                        />
                      </SearchResultsLayout>
                      <SearchResultsLayout
                        resultType="kategorien"
                        results={result.categories}
                        currentPage={pagination.categories}
                        setPage={(x: number) => setPage({ ...pagination, categories: x })}>
                        <CategoriesSearchResults
                          categories={result.categories}
                          pagination={pagination.categories}
                        />
                      </SearchResultsLayout>
                    </div>
                    <div className="md:pl-12 xl:pl-20 md:w-1/2 mb-12">
                      <SearchResultsLayout
                        resultType="artikel"
                        results={result.posts}
                        currentPage={pagination.posts}
                        setPage={(x: number) => setPage({ ...pagination, posts: x })}>
                        <PostSearchResults posts={result.posts} pagination={pagination.posts} />
                      </SearchResultsLayout>
                      <SearchResultsLayout
                        resultType="brands"
                        results={result.brands}
                        currentPage={pagination.brands}
                        setPage={(x: number) => setPage({ ...pagination, brands: x })}>
                        <BrandSearchResults brands={result.brands} pagination={pagination.brands} />
                      </SearchResultsLayout>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default SearchModal
