import React, { createContext, useState } from 'react'
import { useRouter } from 'next/router'

import AuthClient from '@modules/auth/AuthClient'
import { IAuthState } from '@modules/auth/types'
import { IAuthenticateUserResponse } from '@lib/auth/types'
import { trackUserId, trackUserLogin } from '@utils/tracking'
import { createToast } from '@components/Toast'
import { IAppleOAuthData } from '@modules/auth/apple/types'
import { IUser } from '@modules/user/types'

const authClient = new AuthClient()

export type UserContextProps = {
  auth: IAuthState
  login: (email: string, password: string) => Promise<IAuthenticateUserResponse>
  loginWithGoogle: (credential: string) => Promise<IAuthenticateUserResponse>
  loginWithApple: (data: IAppleOAuthData) => Promise<IAuthenticateUserResponse>
  logout: () => void
}

const defaultAuthState = {
  user: null,
  isAuthenticated: false
}

/* eslint-disable */
const defaultValues = {
  auth: defaultAuthState,
  login: (email: string, password: string): Promise<IAuthenticateUserResponse> =>
    Promise.resolve({}),
  loginWithGoogle: (credential: string): Promise<IAuthenticateUserResponse> => Promise.resolve({}),
  loginWithApple: (data: IAppleOAuthData): Promise<IAuthenticateUserResponse> =>
    Promise.resolve({}),
  logout: () => {}
}
/* eslint-enable */

const UserContext = createContext(defaultValues)

export const UserContextProvider: React.FC<{ children }> = ({ children }) => {
  const [auth, setAuth] = useState(defaultAuthState)

  const router = useRouter()

  React.useEffect(() => {
    authClient
      .getUserByBearerToken()
      .then((user) => {
        setAuth({
          user,
          isAuthenticated: true
        })
        return user
      })
      .then((user) => trackUserId(user.id))
      .catch(() => {
        setAuth(defaultAuthState)
      })
  }, [])

  function logout() {
    authClient.logout().then(() => {
      setAuth(defaultAuthState)
    })
  }

  function greetUser(user: IUser) {
    if (user.firstName) {
      createToast(`Willkommen zurück, ${user.firstName}!`, 'success')
    } else {
      createToast('Willkommen zurück!', 'success')
    }
  }

  async function login(email, pw) {
    return authClient.login(email, pw).then((res) => {
      if (!res.error) {
        setAuth({
          user: res.user,
          isAuthenticated: true
        })
        greetUser(res.user)
        const hash = router.asPath.split('#')[1]
        if (hash && hash.includes('referrer')) {
          router.back()
        } else {
          router.push('/')
        }
        trackUserId(res.user.id)
        trackUserLogin()
      }
      return res
    })
  }

  async function loginWithGoogle(credential: string) {
    return authClient.handleGoogleCredentialResponse(credential).then((res) => {
      if (!res.error) {
        setAuth({
          user: res.user,
          isAuthenticated: true
        })
        greetUser(res.user)
        const hash = router.asPath.split('#')[1]
        if (hash && hash.includes('referrer')) {
          router.back()
        } else {
          router.push('/')
        }
        trackUserId(res.user.id)
        trackUserLogin()
      }
      return res
    })
  }

  async function loginWithApple(data: IAppleOAuthData) {
    return authClient.handleAppleCredentialResponse(data).then((res) => {
      if (!res.error) {
        setAuth({
          user: res.user,
          isAuthenticated: true
        })
        greetUser(res.user)
        const hash = router.asPath.split('#')[1]
        if (hash && hash.includes('referrer')) {
          router.back()
        } else {
          router.push('/')
        }
        trackUserId(res.user.id)
        trackUserLogin()
      }
      return res
    })
  }

  return (
    <UserContext.Provider value={{ auth, login, loginWithGoogle, loginWithApple, logout }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
