import jwtDecode from 'jwt-decode'

import cookieManager from '@modules/auth/CookieManager'
import { IAuthenticateUserResponse, IAuthActionResponse } from '@lib/auth/types'
import { IUserAccount } from '@modules/user/types'
import { IAppleOAuthData } from '@modules/auth/apple/types'
import { Jwt, ITokenStore, TokenPair } from '@modules/auth/types'
import { ISleepCheckEntry } from '@lib/sleepcheck/types'
import { SleepCheckApiTransmission, SleepProgramApiTransmission } from '@modules/sleepcheck/types'
import { CoachingApiTransmission } from '@modules/coaching/types'

const EXPIRY_THRESHOLD = 60 * 10 * 1000 // 10 minutes

export default class AuthClient {
  private apiUrl = `/api`

  private tokenStore: ITokenStore

  constructor(tokenStore: ITokenStore = cookieManager) {
    this.tokenStore = tokenStore
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async callApi(path: string, options: Record<string, any>): Promise<any> {
    const response = await fetch(`${this.apiUrl}${path}`, {
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json'
      },
      body: options.body ? JSON.stringify(options.body) : undefined
    })

    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message)
    }
    return response.json()
  }

  public shouldRenewToken(): boolean {
    try {
      const token = this.tokenStore.getAccessToken()
      const decodedToken: Jwt = jwtDecode(token)
      const expiresAt = new Date(decodedToken.exp * 1000).getTime()
      const now = new Date().getTime()
      const shouldRenewToken = expiresAt - now < EXPIRY_THRESHOLD
      return shouldRenewToken
    } catch (e) {
      return true
    }
  }

  public renewAccessToken = async (): Promise<void> => {
    const refreshToken = this.tokenStore.getRefreshToken()
    if (!refreshToken) {
      throw new Error('No refresh token found')
    }
    try {
      const tokenPair: TokenPair = await this.callApi('/auth/refresh-token', {
        method: 'POST',
        body: { refreshToken }
      })
      this.tokenStore.setAuthCookie(tokenPair)
    } catch (error) {
      this.logout()
    }
  }

  async saveSleepCheck(sleepCheck: SleepCheckApiTransmission): Promise<ISleepCheckEntry> {
    const token = this.tokenStore.getAccessToken()
    return this.callApi('/sleepcheck', {
      method: 'POST',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      },
      body: sleepCheck
    })
  }

  async saveCoachingDay(coaching: CoachingApiTransmission): Promise<any> {
    const token = this.tokenStore.getAccessToken()
    return this.callApi('/coaching', {
      method: 'POST',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      },
      body: coaching
    })
  }

  async getDoneCoachingDays(): Promise<any> {
    const token = this.tokenStore.getAccessToken()
    return this.callApi('/coaching', {
      method: 'GET',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      }
    })
  }

  async saveSleepProgramUser(sleepProgram: SleepProgramApiTransmission): Promise<void> {
    const token = this.tokenStore.getAccessToken()
    return this.callApi('/sleep-program', {
      method: 'POST',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      },
      body: sleepProgram
    })
  }

  async saveSleepCheckInterviewSignup(email: string): Promise<void> {
    const token = this.tokenStore.getAccessToken()
    return this.callApi('/sleepcheck-interview-signup', {
      method: 'POST',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      },
      body: { email }
    })
  }

  async login(email: string, password: string): Promise<IAuthenticateUserResponse> {
    const res: IAuthenticateUserResponse = await this.callApi('/auth/login', {
      method: 'POST',
      body: { email, password }
    })

    if (res.error) {
      return {
        error: res.error,
        user: null
      }
    }

    const { accessToken, refreshToken } = res.token
    this.tokenStore.setAuthCookie({
      accessToken,
      refreshToken
    })

    return res
  }

  async logout(): Promise<void> {
    this.tokenStore.removeAuthCookie()
  }

  /**
   *
   * @param defaultCheckoutUrl The default checkout url to redirect to if the user is not logged in
   * @returns associated checkout url (multipass) or default checkout url
   */
  async getSignedCheckoutURL(defaultCheckoutUrl: string): Promise<string> {
    const token = this.tokenStore.getAccessToken()
    if (!token) {
      return defaultCheckoutUrl
    }
    return this.callApi('/auth/associate-checkout', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: { checkoutUrl: defaultCheckoutUrl }
    })
  }

  async getUserByBearerToken(): Promise<IUserAccount> {
    if (this.shouldRenewToken()) {
      await this.renewAccessToken()
    }
    const token = this.tokenStore.getAccessToken()
    if (!token) {
      throw new Error('No token found')
    }
    const res = await this.callApi('/auth/me', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return res
  }

  async verifyEmail(code: string): Promise<IAuthActionResponse> {
    try {
      const response = await this.callApi('/auth/verify-email', {
        method: 'POST',
        body: { code }
      })
      return response
    } catch (error) {
      return {
        error: error.message
      }
    }
  }

  async register(
    email: string,
    password: string,
    reCaptchaToken: string
  ): Promise<IAuthenticateUserResponse> {
    return this.callApi('/auth/register', {
      method: 'POST',
      body: { email, password, reCaptchaToken }
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async requestPasswordReset(email: string, reCaptchaToken: string): Promise<any> {
    return this.callApi('/auth/request-password-reset', {
      method: 'POST',
      body: { email, reCaptchaToken }
    })
  }

  async setNewPassword(code: string, password: string): Promise<IAuthActionResponse> {
    try {
      const response = await this.callApi('/auth/password-reset', {
        method: 'POST',
        body: { code, password }
      })
      return response
    } catch (error) {
      return {
        error: error.message
      }
    }
  }

  async handleGoogleCredentialResponse(credential: string): Promise<IAuthenticateUserResponse> {
    try {
      const response = await this.callApi('/auth/google', {
        method: 'POST',
        body: { credential }
      })

      const { accessToken, refreshToken } = response.token
      this.tokenStore.setAuthCookie({
        accessToken,
        refreshToken
      })
      return response
    } catch (error) {
      return {
        error: error.message
      }
    }
  }

  async handleAppleCredentialResponse(data: IAppleOAuthData): Promise<IAuthenticateUserResponse> {
    try {
      const response = await this.callApi('/auth/apple/login', {
        method: 'POST',
        body: data
      })

      const { accessToken, refreshToken } = response.token
      this.tokenStore.setAuthCookie({
        accessToken,
        refreshToken
      })
      return response
    } catch (error) {
      return {
        error: error.message
      }
    }
  }

  async getSleepCheckResults(sleepCheckId?: string): Promise<ISleepCheckEntry> {
    const token = this.tokenStore.getAccessToken()
    const url = sleepCheckId
      ? `/sleepcheck/latest/?sleep_check_id=${sleepCheckId}`
      : '/sleepcheck/latest'
    try {
      const response: ISleepCheckEntry = await this.callApi(url, {
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined
        }
      })
      return response
    } catch (error) {
      // eslint-disable-next-line no-console
      console.debug('SleepCheckResponseError', error)
      return null
    }
  }
}
