import { shippingThreshold } from '@utils/constants'
import React from 'react'
import ShippingCostCartNote from './ShippingCostCartNote'

type IShoppingCartNotes = {
  checkoutTotalPrice: number
}

const ShoppingCartNotes: React.FC<IShoppingCartNotes> = ({ checkoutTotalPrice }) => {
  const hasShippingNote = checkoutTotalPrice < shippingThreshold

  if (hasShippingNote) {
    return <ShippingCostCartNote checkoutTotalPrice={checkoutTotalPrice} />
  }

  return null
}

export default ShoppingCartNotes
