import React, { useState } from 'react'
import { AnswerOption, Step } from '@modules/sleepcheck/types'

type ChipsProps = {
  step: Step
  setCurrentValue: (value: string[]) => void
}

const Chips = ({ step, setCurrentValue }: ChipsProps) => {
  // const [checkedInputs, setCheckedInputs] = React.useState([])
  const [checkedInputs] = useState([])
  const getId = (option: AnswerOption) => `${step.slug}-${option.label}`

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target

    if (checked) {
      checkedInputs.push(value)
    } else {
      checkedInputs.splice(checkedInputs.indexOf(value), 1)
    }
    // setNextStepSlug(step.answer.getNextStepSlug(answerMap))
    setCurrentValue(checkedInputs)
  }

  return (
    <div className="-mx-2 my-6 flex flex-wrap w-full">
      {step.answer.options.map((option) => {
        const id = getId(option)

        return (
          <div key={option.label} className="grow last:grow-0">
            <input
              type="checkbox"
              key={id}
              id={id}
              name={step.slug}
              value={option.label}
              className="peer appearance-none hidden"
              onChange={(event) => handleChange(event)}
            />
            <label
              htmlFor={id}
              className="block px-2.5 py-1 m-1 flex items-center rounded-2xl justify-between border-2 border-gray-300 text-gray-600 cursor-pointer peer-checked:border-black peer-checked:text-black">
              <span className="text-sm whitespace-nowrap overflow-hidden overflow-ellipsis w-full text-center">
                {option.label}
              </span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default Chips
