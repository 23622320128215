const appUrl = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return `http://localhost:${process.env.PORT || '3000'}`
  }
  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'preview':
      return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    case 'staging':
      return 'https://staging.yoself.de'
    case 'production':
      return 'https://yoself.de'
    case 'development':
      return `http://localhost:${process.env.PORT || '3000'}`
    default:
      return 'http://localhost:3001'
  }
}

export default appUrl
