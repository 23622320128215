import { QueryDocument, ShopifyBrowserClient } from './CartAPI'

export default function makeProductQuery(client: ShopifyBrowserClient) {
  return function productQuery(): QueryDocument {
    const document = client.graphQLClient.document()
    const VariantFragment = document.defineFragment(
      'VariantFragment',
      'ProductVariant',
      (variantRoot) => {
        variantRoot.add('id')
        variantRoot.add('sku')
        variantRoot.add('availableForSale')
      }
    )
    const ProductFragment = document.defineFragment('ProductFragment', 'Product', (productRoot) => {
      productRoot.add('id')
      productRoot.add('vendor')
      productRoot.add('title')
      productRoot.add('tags')
      productRoot.add('availableForSale')
      productRoot.add('variants', { args: { first: 20 } }, (variants) => {
        variants.add('pageInfo', (pageInfo) => {
          pageInfo.add('hasNextPage')
          pageInfo.add('hasPreviousPage')
        })
        variants.add('edges', (edges) => {
          edges.add('cursor')
          edges.add('node', (node) => {
            node.addFragment(VariantFragment)
          })
        })
      })
    })
    document.addQuery([client.graphQLClient.variable('ids', '[ID!]!')], (docRoot) => {
      docRoot.add(
        'nodes',
        {
          args: {
            ids: client.graphQLClient.variable('ids', '[ID!]!')
          }
        },
        (nodes) => {
          nodes.addFragment(ProductFragment)
        }
      )
    })
    return document
  }
}
