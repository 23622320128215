import React from 'react'

interface IShoppingCartProgressBar {
  percent: number
}

const ShoppingCartProgressbar: React.FC<IShoppingCartProgressBar> = ({ percent }) => {
  if (percent > 0 && percent < 100) {
    return (
      <div className="w-full bg-white rounded-full mb-3">
        <div
          className="bg-black text- font-medium text-white text-center p-0.5 rounded-l-full"
          style={{
            width: `${percent}%`
          }}>
          {percent.toFixed(0)} %
        </div>
      </div>
    )
  }
  return null
}

export default ShoppingCartProgressbar
