import {
  IBrandSearchItem,
  ICategorySearchItem,
  IPostSearchItem,
  IProductSearchItem
} from '@lib/SearchIndexService/types'
import appUrl from '@utils/appUrl'
import { ISearchItems, ISearchResult } from './interfaces'

export default function mapSearchResults(data: { hits: ISearchItems[] }): ISearchResult {
  const itemsWithLinks = data.hits.map((hit) => ({ ...hit, link: `${appUrl()}${hit.link}` }))

  const products: IProductSearchItem[] = (itemsWithLinks as IProductSearchItem[]).filter(
    (hit) => hit.type === 'product'
  )
  const posts = (itemsWithLinks as IPostSearchItem[]).filter((hit) => hit.type === 'post')
  const brands = (itemsWithLinks as IBrandSearchItem[]).filter((hit) => hit.type === 'brand')
  const categories = (itemsWithLinks as ICategorySearchItem[]).filter(
    (hit) => hit.type === 'category'
  )
  return { products, posts, brands, categories }
}
