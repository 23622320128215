import React, { FormEventHandler, useContext, useEffect } from 'react'
import img from '@assets/images/sleep-program/top-image.jpeg'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import SleepProgramContext from '@context/SleepProgramContext'
import SleepCheckContext from '@context/SleepCheckContext'
import trackEvent from '@lib/tracking/trackEvent'
import router from 'next/router'
import TopImage from './TopImage'

const SignUp = () => {
  const { setUser, user, submitUserData } = useContext(SleepProgramContext)
  const { result } = useContext(SleepCheckContext)

  useEffect(() => {
    if (result?.email) setUser({ ...user, email: result.email })
  }, [result?.email])

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    const name = `${user.firstName} ${user.lastName}`
    const calendlyLink = `https://calendly.com/yoself/sleepcoaching?name=${name}&email=${user.email}`
    e.preventDefault()
    await submitUserData()
    await trackEvent({ event: 'sleep_program_first_interview_signup' })
    router.push(calendlyLink)
  }

  return (
    <div className="overflow-auto">
      <TopImage img={img} />
      <div className="p-6">
        <h4 className="text-sm uppercase mb-2">yoself Sleep Coaching</h4>
        <h3 className="font-bold text-xl mb-3">
          Nur noch ein Schritt zu mehr Gesundheit und Lebensenergie
        </h3>
        <p className="pb-2">
          In unserem Erstgespräch mit einem unserer Sleep Coaches bestimmen wir deine Situation und
          wie wir dir am besten helfen können. Kostenlos und unverbindlich.
        </p>
        <p className="pb-4">
          Sichere dir jetzt einen Termin und finde mit uns <b>zu mehr Energie & Vitalität.</b>
        </p>
        <form onSubmit={handleSubmit} className="flex flex-col mt-4">
          <div className="flex md:flex-row">
            <input
              className="rounded-sm mb-4 w-1/2 mr-4"
              type="text"
              placeholder="Vorname"
              autoComplete="given-name"
              value={user.firstName}
              onChange={(e) => setUser({ ...user, firstName: e.target.value })}
              required
            />
            <input
              className="rounded-sm mb-4 w-1/2"
              type="text"
              placeholder="Nachname"
              autoComplete="family-name"
              value={user.lastName}
              onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              required
            />
          </div>
          <input
            className="rounded-sm mb-3"
            type="email"
            placeholder="E-Mail"
            autoComplete="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            required
          />
          {/* <div className="my-2 text-sm">
            <input type="checkbox" id="privacy" className="rounded mr-2 mb-1" required />
            <label htmlFor="privacy">
              Ich möchte Informationen zum Programm erhalten und habe die{' '}
              <Link href="/datenschutz" className="underline">
                Datenschutzerklärung
              </Link>{' '}
              gelesen.
            </label>
          </div> */}
          <button
            type="submit"
            className="bg-black text-white my-3 flex items-center justify-center border-2 border-gray-200 w-full h-12 rounded disabled:bg-slate-100 disabled:border-white">
            <ArrowRightIcon className="h-12 p-2" />
            <span className="font-bold uppercase ml-4">Gespräch vereinbaren</span>
          </button>
        </form>
        <p className="text-center text-sm mt-4 text-gray-500">
          Nach dem Absenden wirst du für die Terminauswahl zur App Calendly weitergeleitet.
        </p>
      </div>
    </div>
  )
}

export default SignUp
