import Image from 'next/image'
import React from 'react'

const logos = ['paypal', 'klarna', 'amazonPay', 'visa', 'mastercard', 'applePay', 'googlePay']

const PaymentOptions = () => (
  <div className="mx-5 md:mx-6 mt-4 pt-1 md:px-1">
    <div className="flex justify-between grayscale">
      {logos.map((logo) => (
        <Image
          alt={logo}
          key={logo}
          src={`/images/payment/${logo}.svg`}
          height={26.66}
          width={40}
          className="h-auto"
        />
      ))}
    </div>
  </div>
)

export default PaymentOptions
