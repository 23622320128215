import React from 'react'

interface IContainer {
  className?: string
  children: React.ReactNode
}

const Container: React.FC<IContainer> = ({ children, className }) => (
  <div className={`${className} mx-auto`}>{children}</div>
)

export default Container
