import React, { createContext, useEffect } from 'react'
import { useRouter } from 'next/router'

import AuthClient from '@modules/auth/AuthClient'
import { useAuthState } from 'hooks/useAuthState'
import { toast } from 'react-hot-toast'
import trackEvent from '@lib/tracking/trackEvent'

type SleepCheckContextProps = {
  children?: JSX.Element
}

/* eslint-disable */
const defaultValues = {
  answersMap: new Map(),
  isLoading: false,
  result: null,
  setAnswersMap: (answersMap: Map<any, any>) => {},
  setResult: (result: any) => {}
}
/* eslint-enable */

const SleepCheckContext = createContext(defaultValues)

const authClient = new AuthClient()

export const SleepCheckContextProvider = ({ children }: SleepCheckContextProps) => {
  const router = useRouter()
  const [answersMap, setAnswersMap] = React.useState(new Map())
  const [result, setResult] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { isAuthenticated } = useAuthState()

  useEffect(() => {
    async function getSleepCheckResults(sleepcheckId) {
      setIsLoading(true)
      const res = await authClient.getSleepCheckResults(sleepcheckId)
      setIsLoading(false)

      if (router.asPath.includes('/topics/schlaf-typ-') && res === null) {
        trackEvent({ event: 'sleep_check_missing' })
        await router.push('/topics/gesundheit-durch-schlaf#sleepcheck')
        toast(
          'Wir können kein Sleep-Check-Ergebnis für dich finden, bitte führe einen neuen Sleep-Check durch.'
        )
      }
      setResult(res)
    }
    if (router.isReady) {
      const { sleep_check_id } = router.query
      getSleepCheckResults(sleep_check_id)
    }
  }, [router.isReady, isAuthenticated])

  return (
    <SleepCheckContext.Provider
      value={{
        answersMap,
        isLoading,
        result,
        setAnswersMap,
        setResult
      }}>
      {children}
    </SleepCheckContext.Provider>
  )
}

export default SleepCheckContext
