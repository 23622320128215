import { StaticImageData } from 'next/image'

export enum answerType {
  RANGE = 'range',
  YES_NO = 'yes-no',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  CHIPS = 'chips'
}

export type Step = {
  slug: string
  image: string | StaticImageData
  question: string
  category: string
  answer: {
    getNextStepSlug: (answerMap: Map<string, string>) => string
    type: answerType
    options?: AnswerOption[]
  }
}

export type AnswerOption = {
  label: string
}

export type SleepCheckAnswers = {
  productRecommendations: boolean
  quality: number // 1-100
  hours: string // 0-3, 3-5, 5-7, 7-8, mehr als 8
  wellRested: boolean
  easyStart: boolean
  improveQuality: boolean
  stressed: boolean
  windDown: boolean
  worries: boolean
  comeToRest: boolean
  notTired: boolean
  fear: boolean
  thoughts: boolean
  wakeUp: boolean
  childrenPets: boolean
  toilet: boolean
  hungerThirst: boolean
  externalInfluences: boolean
  wakeUpEarlier: boolean
  threeWeeks: boolean
  devices: boolean
  sameTime: boolean
  caffeine: boolean
  sweatFreeze: boolean
  sweat: boolean
  freeze: boolean
  workInBedroom: boolean
  physicallyActive: boolean
  meals: boolean
  relaxation: boolean
  routine: boolean
  healthyFood: boolean
  comfortable: boolean
  lookImportant: boolean
  measures: string[]
  age: string // 'bis 20 Jahre', '20-29 Jahre', '30-39 Jahre', '40-49 Jahre', '50-59 Jahre', 'ab 60 Jahre'
  gender: string // 'männlich', 'weiblich', 'divers'
  children: boolean
  mate: boolean
  shiftWork: boolean
  sleepProgram: boolean
  expectations: string
}

export type SleepCheckApiTransmission = {
  email: string
  sleepCheck: SleepCheckAnswers
}

export type SleepProgramApiTransmission = {
  firstName: string
  lastName: string
  email: string
  isSleepProgramTester: boolean | null
}
