import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'

import { Step } from '@modules/sleepcheck/types'

import { ScreenIDs, screens } from '@modules/sleepcheck/steps'

type ImageFaderProps = {
  steps: { [key: string]: Step }
  currentStep: Step
  screen: ScreenIDs
}

const ImageFader = ({ steps, currentStep, screen }: ImageFaderProps) => (
  <div className="bg-gray-300 relative rounded-t overflow-hidden">
    {Object.values(steps).map((step) => (
      <div
        key={step.slug}
        className={classNames(
          'absolute top-0 left-0 right-0 aspect-w-5 aspect-h-3 transition-opacity duration-1000 first:relative',
          {
            'opacity-100': step.slug === currentStep.slug,
            'opacity-0': step.slug !== currentStep.slug
          }
        )}>
        <Image
          src={step.image}
          alt="Sleepcheck"
          fill
          placeholder="blur"
          className="object-cover"
          sizes="(min-width: 767px) 800px, 100vw"
        />
      </div>
    ))}
    {Object.values(ScreenIDs).map((s) => {
      if (s === ScreenIDs.SLEEP_CHECK) return null

      return (
        <div
          key={s}
          className={classNames(
            'absolute top-0 left-0 right-0 aspect-w-5 aspect-h-3 transition-opacity duration-1000 first:relative',
            {
              'opacity-100': screen === s,
              'opacity-0': screen !== s
            }
          )}>
          <Image
            src={screens[s].image}
            alt="Sleepcheck"
            fill
            className="object-cover"
            placeholder="blur"
            sizes="(min-width: 767px) 800px, 100vw"
          />
        </div>
      )
    })}
  </div>
)

export default ImageFader
