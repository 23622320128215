import React, { useContext, useEffect } from 'react'
import { IMenuItem } from '@modules/menu/types'
import classNames from 'classnames'
import UiContext from '@context/UiContext'
import { useRouter } from 'next/router'

type TopMenuItemProps = {
  item: IMenuItem
}

const TopMenuItem = ({ item }: TopMenuItemProps) => {
  const { activeMenuSlide, setDesktopMenuOpen } = useContext(UiContext)

  const router = useRouter()

  useEffect(() => {
    if (!activeMenuSlide) return
    const element = document.getElementById(activeMenuSlide)
    document.getElementById('menu-scroll-area').scrollLeft = element.offsetLeft
  }, [router.asPath])

  const scrollToElement = (e) => {
    e.preventDefault()
    const element = document.getElementById(item.id)
    element.parentElement.scrollTo({
      left: element.offsetLeft,
      behavior: window.innerWidth < 768 ? 'smooth' : 'auto'
    })
  }

  return (
    <li key={item.id}>
      <a
        className="pt-2 pb-1.5 inline-block uppercase font-bold text-sm md:px-1 lg:px-4 md:text-base"
        href={`#${item.id}`}
        onClick={(e) => scrollToElement(e)}
        onFocus={(e) => {
          setDesktopMenuOpen(true)
          scrollToElement(e)
        }}
        onMouseOver={(e) => {
          setDesktopMenuOpen(true)
          scrollToElement(e)
        }}>
        <span
          className={classNames('border-b-2 pb-0.5 inline-block whitespace-nowrap', {
            'border-black': activeMenuSlide === item.id,
            'border-transparent': activeMenuSlide !== item.id
          })}>
          {item.title}
        </span>
      </a>
    </li>
  )
}

export default TopMenuItem
