import React from 'react'

export default function useClickOutsideHandler(
  refs: React.RefObject<HTMLElement>[],
  handler: (event: MouseEvent) => void
): void {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!refs.some((ref) => ref.current && ref.current.contains(event.target as Node))) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [refs, handler])
}
