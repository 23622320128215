import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import trackEvent from '@lib/tracking/trackEvent'
import AuthClient from '@modules/auth/AuthClient'
import SleepCheckContext from '@context/SleepCheckContext'

type TesterRegistrationProps = {
  setFakeCalculation: (value: boolean) => void
}

const authClient = new AuthClient()

const TesterRegistration = ({ setFakeCalculation }: TesterRegistrationProps) => {
  const { result } = useContext(SleepCheckContext)

  const handleSubmit = async (isTester: boolean) => {
    if (isTester) {
      trackEvent({ event: 'sleep_check_interview_signup' })
      try {
        await authClient.saveSleepCheckInterviewSignup(result.email)
        setFakeCalculation(true)
      } catch (err) {
        console.error(err)
      }
    } else {
      setFakeCalculation(true)
    }
  }

  return (
    <motion.div
      className="p-6 overflow-y-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}>
      <h3 className="font-bold text-lg">Vielen Dank für deine Anmeldung</h3>
      <h4 className="font-bold mt-4">Eine letzte kurze Frage:</h4>
      <p className="mb-2 mt-4">
        Wir sind ständig daran interessiert, den Sleep-Check besser zu machen.
      </p>
      <p className="pb-2">
        Hättest du Lust, uns in den nächsten Tagen in einem kurzen Gespräch (Telefon oder
        Video-Call) ein paar Fragen zu beantworten?
      </p>
      <p className="pb-2">
        Als Dankeschön erhältst du im Anschluss an das Interview einen{' '}
        <strong>100 €-Gutschein</strong> für den Einkauf auf yoself.
      </p>
      <p className="pb-2">Vielen lieben Dank vorab, Dein yoself Team</p>
      <button
        type="button"
        onClick={() => handleSubmit(true)}
        className="bg-black text-white my-3 flex items-center justify-center border-2 border-gray-200 w-full h-12 rounded disabled:bg-slate-100 disabled:border-white">
        <span className="ml-4 font-bold uppercase">Ja, ich möchte Euch unterstützen</span>
      </button>
      <button
        type="button"
        onClick={() => handleSubmit(false)}
        className="flex items-center justify-center rounded border-2 border-gray-200 w-full h-12 disabled:text-gray-200">
        <span className="ml-4 font-bold uppercase">Lieber nicht</span>
      </button>
    </motion.div>
  )
}

export default TesterRegistration
