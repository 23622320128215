import React from 'react'
import { shippingThreshold } from '@utils/constants'
import { shopifyToEuro } from '../../utils'
import ShoppingCartProgressbar from './ShoppingCartProgressBar'

interface IShippingCostCartNote {
  checkoutTotalPrice: number
}

const ShippingCostCartNote: React.FC<IShippingCostCartNote> = ({ checkoutTotalPrice }) => (
  <div className="text-gray-800 bg-gray-300 rounded text-sm mx-3 mb-3 py-2 px-2 lg:mx-5">
    <ShoppingCartProgressbar percent={(checkoutTotalPrice / shippingThreshold) * 100} />
    Nur noch {shopifyToEuro(shippingThreshold - checkoutTotalPrice)} für versandkostenfreie
    Lieferung.
  </div>
)

export default ShippingCostCartNote
