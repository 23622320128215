import React from 'react'
import * as Sentry from '@sentry/nextjs'

import Layout from '@components/Layout/Layout'
import ShopContext from '@context/ShopContext'
import { IMenuItem } from '@modules/menu/types'

const stripFunctions = (obj: any) => JSON.parse(JSON.stringify(obj)) // eslint-disable-line @typescript-eslint/no-explicit-any

type Props = {
  children: React.ReactElement
  topMenu: IMenuItem[]
}

export default class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static contextType = ShopContext

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error, {
      extra: stripFunctions(this.context)
    })
  }

  render() {
    const { children, topMenu } = this.props
    const { hasError } = this.state
    const handleClick = () => {
      window.location.reload()
    }
    if (hasError) {
      return (
        <Layout title="Fehler" description="" topMenu={topMenu}>
          <section className="flex items-center flex-col flex-wrap justify-center bg-gray-200 space-y-4 text-center text-black pb-18 pt-18 sm:pb-12 sm:pt-16 md:pt-32 lg:pb-16 lg:pt-16 px-3 md:h-40vh">
            <p className="text-lg md:text-2xl xl:text-4xl">
              Jetzt sind wir kurz aus der Balance geraten, bitte einmal neu laden.
            </p>
            <button
              type="button"
              onClick={handleClick}
              className="btn--black text-white font-bold py-2 px-4 rounded">
              Neu laden
            </button>
          </section>
        </Layout>
      )
    }

    return children
  }
}
