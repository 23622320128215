import Cookies from 'universal-cookie'

import { TokenPair, ITokenStore } from '@modules/auth/types'

const cookies = new Cookies()

export const AUTH_COOKIE_NAME = 'yoself-auth'

class CookieManager implements ITokenStore {
  public getAccessToken(): string | null {
    return cookies.get(AUTH_COOKIE_NAME)?.accessToken ?? null
  }

  public getRefreshToken(): string | null {
    return cookies.get(AUTH_COOKIE_NAME)?.refreshToken ?? null
  }

  public setAuthCookie(tokenPair: TokenPair): void {
    cookies.set(AUTH_COOKIE_NAME, JSON.stringify(tokenPair), {
      maxAge: 3600 * 24 * 30 * 6, // 6 months
      sameSite: 'strict',
      path: '/'
    })
  }

  public removeAuthCookie(): void {
    cookies.remove(AUTH_COOKIE_NAME, { path: '/', domain: '' })
  }
}

export default new CookieManager()
