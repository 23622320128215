// NOTE: To avoid floating point issues, we are using the currency.js library here.
import currency from 'currency.js'

export const toEuro = (amount: number): string =>
  currency(amount, { symbol: '€', separator: '.', decimal: ',', pattern: '# !' }).format()

export const shopifyToEuro = (amount: number): string =>
  currency(amount, { symbol: '€', separator: '.', decimal: '.', pattern: '# !' }).format({
    decimal: ','
  })

export const toDecimals = (amount: number): string => currency(amount).value.toFixed(2).toString()

export const toCents = (amount: string | number): number => currency(amount).intValue

export const isNew = (date: string) =>
  new Date(date) > new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)

export const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString('de-DE', {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
