import React, { useReducer, useState } from 'react'
import shopifyClient from '@lib/shopify/api/checkout/CartAPI'
import trackRemoveFromCart from '@lib/tracking/trackRemoveFromCartEvent'
import trackAddToCartEvent from '@lib/tracking/trackAddToCartEvent'
import { IShopifyCheckout, IShopifyLineItem } from '@lib/shopify/interfaces'
import {
  mapShopifyLineItemToTrackingData,
  mapVariantProductToTrackingData
} from '@lib/tracking/mapToTrackingData'
import CheckoutSingleton from '@lib/shopify/CheckoutSingleton'
import { mapProductToCartItem } from '@modules/products/utils'
import { LOCAL_STORAGE_KEY } from '@utils/constants'
import { IProduct, IProductSnippetsBySlug, IProductVariant } from '@modules/products/types'
import CartService from '@lib/cartService'
import browserStorage from '@lib/browserStorage'
import { useRouter } from 'next/router'
import AuthClient from '@modules/auth/AuthClient'
import { IGlobalContext } from '../interfaces'
import productReducer from './productReducer'
import cartItemReducer, { ADD_CART_ITEMS } from './cartItemReducer'

const cartService = new CartService(shopifyClient)

/* eslint-disable */
export const defaultValues = {
  loading: false,
  productsBySlug: {},
  cartItems: [],
  checkout: {
    id: null,
    lineItems: [],
    lineItemsSubtotalPrice: {
      amount: ''
    },
    discountApplications: [],
    subtotalPrice: {
      amount: ''
    },
    totalPrice: {
      amount: ''
    },
    webUrl: '',
    completedAt: null
  },
  shopifyClient,
  removeLineItem: (checkoutId: string, lineItem: IShopifyLineItem): void => {},
  updateLineItem: (checkoutId: string, lineItem: IShopifyLineItem, quantity: number): void => {},
  setProductsBySlug: (productsBySlug: IProductSnippetsBySlug): void => {},
  addProductToCart: (variant: IProductVariant, product: IProduct, quantity: number): void => {},
  getCheckoutURL: (): Promise<string> => Promise.resolve(''),
  applyDiscount: (discountCode: string): void => {}
}
/* eslint-enable */

const authClient = new AuthClient()
const ShopContext = React.createContext(defaultValues)
const checkoutSingleton = new CheckoutSingleton(shopifyClient, browserStorage)

export const ShopContextProvider: React.FC<IGlobalContext> = ({ children, productMap }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [cartItems, dispatchCartProductAction] = useReducer(
    cartItemReducer,
    defaultValues.cartItems
  )
  const [productsBySlug, dispatchProductAction] = useReducer(productReducer, productMap || {})

  const setCheckoutItem = (_checkout) => {
    browserStorage.setItem(LOCAL_STORAGE_KEY, _checkout.id)
    setCheckout(_checkout)
  }

  const initializeCheckout = async () => {
    const newCheckout = await checkoutSingleton.getCheckout()
    setCheckoutItem(newCheckout)
    return newCheckout.id
  }

  const applyDiscount = async (discountCode: string) => {
    const newCheckout = await checkoutSingleton.applyDiscount(discountCode)
    setCheckoutItem(newCheckout)
  }

  async function initializeDiscount() {
    if (!router.isReady) {
      return
    }

    const { coupon } = router.query
    if (coupon) {
      await applyDiscount(coupon as string)
    }
  }

  // eslint-disable-next-line consistent-return
  const initializeCart = async () => {
    const existingCheckout: IShopifyCheckout = await checkoutSingleton.getCheckout()

    if (existingCheckout.completedAt) {
      browserStorage.removeItem(LOCAL_STORAGE_KEY)
      checkoutSingleton.reset()
      return initializeCart()
    }

    if (!existingCheckout.completedAt) {
      const productIds = existingCheckout.lineItems.map((lineItem) => lineItem.variant.product.id)
      const items = await cartService.getItemsByProductIds(productIds)
      dispatchCartProductAction({
        type: ADD_CART_ITEMS,
        payload: {
          cartItems: items
        }
      })

      setCheckoutItem(existingCheckout)
    }
  }

  const addVariantToCart = async (
    variant: IProductVariant,
    product: IProduct,
    quantity: number
  ) => {
    const mappedCartProduct = mapProductToCartItem(product)

    setLoading(true)
    dispatchCartProductAction({
      type: ADD_CART_ITEMS,
      payload: {
        cartItems: [mappedCartProduct]
      }
    })

    const existingCheckout = await checkoutSingleton.getCheckout()
    const checkoutID = existingCheckout.id
    const lineItemsToUpdate = [
      {
        variantId: variant.shopifyVariantId,
        quantity
      }
    ]

    return shopifyClient.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((response) => {
      trackAddToCartEvent(mapVariantProductToTrackingData(variant, product, 1))
      setCheckout(response)
      setLoading(false)
    })
  }

  const updateLineItem = async (
    checkoutID: string,
    lineItem: IShopifyLineItem,
    quantity: number
  ) => {
    const lineItemsToUpdate = [
      {
        id: lineItem.id,
        quantity
      }
    ]
    return shopifyClient.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((response) => {
        if (quantity > lineItem.quantity) {
          trackAddToCartEvent(mapShopifyLineItemToTrackingData(lineItem, cartItems, quantity))
        } else {
          trackRemoveFromCart(lineItem, cartItems, quantity)
        }
        setCheckout(response)
      })
  }

  const removeLineItem = (checkoutID, lineItem: IShopifyLineItem) =>
    shopifyClient.checkout.removeLineItems(checkoutID, [lineItem.id]).then((res) => {
      trackRemoveFromCart(lineItem, cartItems, lineItem.quantity)
      setCheckout(res)
    })

  const getCheckoutURL = async (): Promise<string> => {
    const checkoutUrl = await authClient.getSignedCheckoutURL(checkout.webUrl)
    return checkoutUrl
  }

  const getCart = async () => {
    try {
      await initializeCheckout()
      await initializeCart()
      await initializeDiscount()
    } catch (e) {
      console.error('Initializing Cart/Checkout failed', e)
      browserStorage.setItem(LOCAL_STORAGE_KEY, null)
      checkoutSingleton.reset()
    }
  }

  React.useEffect(() => {
    getCart()
  }, [router.isReady])

  return (
    <ShopContext.Provider
      value={{
        ...defaultValues,
        productsBySlug,
        setProductsBySlug: (_productsBySlug) =>
          dispatchProductAction({
            type: 'FETCH_PRODUCTS',
            payload: { productsBySlug: _productsBySlug }
          }),
        checkout,
        cartItems,
        addProductToCart: addVariantToCart,
        updateLineItem,
        removeLineItem,
        loading,
        getCheckoutURL,
        applyDiscount
      }}>
      {children}
    </ShopContext.Provider>
  )
}

export default ShopContext
